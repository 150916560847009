.sa1 {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 1.375rem; // 22px
  letter-spacing: -0.055rem; // -0.88px
}
.sa2 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.875rem !important; // 14px
  letter-spacing: -0.0175rem; // -0.28px
  line-height: 1.5rem !important; //24px
}
.sa3 {
  font-family: Open Sans;
  font-size: 1.5rem; // 24px
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; // 32px
  letter-spacing: -0.03rem; // -0.48px
}
.sa4 {
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 1.625rem; // 26px
  letter-spacing: -0.02rem; // -0.32px
}
.font-15px {
  font-size: 15px;
}
.font-16px {
  font-size: 1rem;
}
.mt-52px {
  margin-top: 3.25rem;
}
.preview-email {
  padding: 1.5rem;
  border: 2px solid #000;
  background: #f3f6f6;
  color: #000;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.02rem;
  line-height: 1.625rem; //26px
  border-radius: 5px;
}
.email-field {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
  font-size: 0.938rem !important;
  line-height: 1.625rem !important;
  letter-spacing: -0.019rem !important;
  padding: 20px 10px !important;
}
.deleteBtn {
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #a71818;
}
textarea {
  resize: none;
}
.sendBtn {
  color: #000;
  padding: 12px 24px;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    color: #000;
    background-color: #efc030;
  }
}
.abroad-blue {
  background-color: #0a223c;
}
.abroad-blue {
  &:hover {
    background-color: #0a223c !important;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:disabled {
    background-color: #0a223c !important;
  }
}
.input-lable {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.625rem;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.request-wrapper {
  overflow-y: scroll;
  max-height: 30rem !important;
}
.plusBtn {
  font-size: 40px !important;
  letter-spacing: normal !important;
  padding: 8px 8px !important;
  font-weight: 300 !important;
}
.resDowBtn {
  height: 2.831rem;
  border-radius: 21.9px !important;
  padding: 14px 24px;
}

.input-focus {
  &:focus {
    box-shadow: none;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  &:focus {
    border-color: #b22222;
    box-shadow: none;
  }
}
.cust-360-textarea {
  resize: 'none' !important ;
  min-height: 3.125rem;
  width: 100%;
  height: auto;
  font-size: 1rem;
  padding: 12px;
  overflow: hidden;
  font-family: 'Open Sans';
  border: 1.5px solid #000;
  border-radius: 5px;

  vertical-align: middle;
}
.btn-send-req {
  &:hover {
    background-color: #efc030;
    color: #000;
  }
  //   {
  //     background-color: #d5a000;
  //     color: white;
  // }
}
.tableBorder {
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: none !important;
    border-bottom: none !important;
  }
  td {
    border-top: 1px solid #000;
  }
}
.navTabs {
  display: inline-flex;
  padding: '10px 16px';
  border: 1px solid #0a223c;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.625rem; // 10px
  letter-spacing: 0.125rem; // 2px
  line-height: normal;
  text-transform: uppercase;
  &:hover {
    background: #0a223c;
    color: white;
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    background: #0a223c;
    color: white;
  }
}
.selection-field {
  border-radius: 5px;
  padding: 7px 18px;
  border: 1px solid #000;
}
.quest-selection-field {
  border-radius: 5px;
  padding: 7px 7px;
  border: 1px solid #000;
}
// .image-container {
//   position: relative;
//   display: inline-block;
//   transition: opacity 0.3s ease;
//   &:hover {
//     opacity: 0.3;
//   }
// }

.image-container {
  &:hover .delete-text {
    display: block;
    font-weight: 600;
  }
}

.image-container-gallery {
  position: relative;
  display: inline-block;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 199px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 1.2rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.me-10px {
  margin-right: 0.625rem;
}
.custom-heading {
  font-family: Montserrat !important;
  font-weight: 600 !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
  color: black;
}
.overlayImage:hover .delete-text {
  color: red;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  font-family: 600;
  cursor: pointer;
  opacity: 1;
}

.delete-text {
  transition: color 0.3s ease;
}
.image-container-gallery:hover .overlayImage {
  opacity: 1;
}
.inquiries-modal {
  .modal-content {
    border-radius: 10px;
    background: $white;
    border: none;
  }
  .payment-modal-header {
    border-radius: 5px 5px 0px 0px;
    background: #0a223c;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .payment-modal-title {
    font-family: Montserrat;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0.125rem;
    color: #efc030;
    text-transform: uppercase;
  }
}
.quest-modal {
  .modal-dialog {
    max-width: 720px;
    display: flex;
    justify-content: center;
  }
}
.questImage-container {
  position: relative;
}
.uploading-text-highlight {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploading-text-location {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploading-text-quest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploading-text-quest-faculty {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.uploading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.itenaryImage {
  opacity: 0.3;
}

.location-field {
  input:disabled {
    background-color: #f3f6f6 !important;
  }
}
.empty {
  border: 1px solid red;
}

.rmdp-arrow {
  border: 1px solid #efc030 !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-week-day {
  color: #efc030 !important;
}
.rmdp-day.rmdp-today span {
  background-color: #f5d36e !important;
  color: #fff !important;
}
s .rmdp-calendar,
.rmdp-calendar:focus {
  outline-color: #efc030 !important;
}
.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #efc030 !important;
  color: #fff !important;
}
.rmdp-arrow-container:hover {
  background-color: #efc030 !important;
  box-shadow: none !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #efc030 !important;
  box-shadow: none !important;
  color: #fff !important;
}
