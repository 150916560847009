.archive-intentions-section {
  margin: 2.5rem 0rem 2.5rem 0rem;
}
.intention-links {
  font-weight: 600;
  &:hover {
    font-weight: 700;
    text-decoration: none !important;
  }
}
.insights-card {
  width: 12.438rem !important;
  height: 12.438rem !important;
  &:hover {
    .icon-add-insight::before {
      color: #efc030 !important;
    }
  }
}
.growth-intention .swiper-wrapper {
  padding-bottom: 1rem;
}
.add-intention-btn {
  font-size: 0.563rem !important;
  line-height: 0.688rem !important;
  letter-spacing: 0.2rem !important;
}
