@font-face {
  font-family: 'icon';
  src: url('../assets/fonts/icon.eot?i2w697');
  src: url('../assets/fonts/icon.eot?i2w697#iefix') format('embedded-opentype'),
    url('../assets/fonts/icon.ttf?i2w697') format('truetype'),
    url('../assets/fonts/icon.woff?i2w697') format('woff'),
    url('../assets/fonts/icon.svg?i2w697#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: '\e911';
  color: #fff;
}
.icon-check:before {
  content: '\e90e';
  color: #efc030;
}
.icon-user:before {
  content: '\e90d';
}
.icon-close:before {
  content: '\e90c';
}
.icon-feather:before {
  content: '\e90b';
}
.icon-backward:before {
  content: '\e907';
}
.icon-forward:before {
  content: '\e908';
}
.icon-volume:before {
  content: '\e904';
}
.icon-mute:before {
  content: '\e906';
}
.icon-fav:before {
  content: '\e903';
}
.icon-fav-outline:before {
  content: '\e905';
}
.icon-previous:before {
  content: '\e901';
}
.icon-next:before {
  content: '\e902';
}
.icon-journal:before {
  content: '\e900';
}
.icon-journey:before {
  content: '\e92a';
}
.icon-coaching-white:before {
  content: '\e926';
}
.icon-analytics:before {
  content: '\e928';
}
.icon-briefcase:before {
  content: '\e91f';
}
.icon-triangle-outline:before {
  content: '\e919';
}
.icon-triangle:before {
  content: '\e91a';
}
.icon-plus .path1:before {
  content: '\e909';
  opacity: 0.5;
}
.icon-plus .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-plus .path3:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-growth-plan:before {
  content: '\e90a';
}
.icon-assessments:before {
  content: '\e90f';
}
.icon-home-outline:before {
  content: '\e913';
}
