$saffron: (
  '300': #efc030,
  '300-5': rgba(239, 192, 48, 0.5),
  '300-3': rgba(239, 192, 48, 0.3),
  '600': #d5a000,
  '700': #bf9000
);

$red: (
  '500': #bc4c6a,
  '600': #c73939,
  '700': #7e2d55,
  '800': #a71818
);

$gray: (
  '100': #f2f5f5,
  '101': #f9f9f9,
  '102': #d2d2d2,
  '103': #f0f0f0,
  '104': #f3f6f6,
  '200': #999999,
  '201': #706f6f,
  '300': #959595,
  '301': #c8c8c8,
  '400': #c2c2c2,
  '401': #b1b1b1,
  '500': #eeeeee,
  '501': #828282,
  '600': #646464,
  '700': #333333,
  '800': #a0a0a0
);

$dark: (
  '400': #101010,
  '500': #212121,
  '600': #141414,
  '700': #1e1e1e,
  '800': #282828,
  '900-4': rgba(0, 0, 0, 0.4),
  '900-5': rgba(0, 0, 0, 0.5),
  '900-7': rgba(0, 0, 0, 0.7),
  '900': $black
);

$blue: (
  '100': #69b1d8,
  '200': #0076bd,
  '300': #deeeff,
  '400': #0066ff,
  '500': #0a223c,
  '600': #123c69
);

$green: (
  '100': #509f92,
  '200': #37763b,
  '300': #377638,
  '400': #d9efdc,
  '500': #285238
);

$custom-colors: (
  'saffron': $saffron,
  'gray': $gray,
  'dark': $dark,
  'blue': $blue,
  'red': $red,
  'green': $green
);

$emerald: #39c74b;

$customspacers: 0.25rem;
$colors: map-merge(
  $colors,
  (
    black: $black,
    'black-100': #212121,
    'white': $white,
    'dark-700': #1e1e1e,
    'red-600': #c73939,
    'green-100': #509f92
  )
);
$customsize: () !default;
$customsize: map-merge(
  (
    0: 0,
    1: $customspacers,
    2: (
      $customspacers * 2
    ),
    3: (
      $customspacers * 3
    ),
    4: (
      $customspacers * 4
    ),
    5: (
      $customspacers * 5
    ),
    6: (
      $customspacers * 6
    ),
    8: (
      $customspacers * 8
    ),
    12: (
      $customspacers * 12
    ),
    16: (
      $customspacers * 16
    ),
    20: (
      $customspacers * 20
    ),
    35: (
      $customspacers * 35
    ),
    40: (
      $customspacers * 40
    ),
    60: (
      $customspacers * 60
    ),
    80: (
      $customspacers * 80
    )
  ),
  $customsize
);
$bg-opacity: 1;
$opacities: () !default;

$opacities: map-merge(
  (
    0: 0,
    10: 0.1,
    15: 0.15,
    25: 0.25,
    50: 0.5,
    60: 0.6,
    75: 0.75,
    1: 1
  ),
  $opacities
);

// border radius
$custom-border-radius: 1rem;

$column-width-12: (100% / 12);
$custom-widths: (
  '1\/12': #{$column-width-12},
  '2\/12': calc(#{$column-width-12} * 2),
  '3\/12': calc(#{$column-width-12} * 3),
  '4\/12': calc(#{$column-width-12} * 4),
  '5\/12': calc(#{$column-width-12} * 5),
  '6\/12': calc(#{$column-width-12} * 6),
  '7\/12': calc(#{$column-width-12} * 7),
  '8\/12': calc(#{$column-width-12} * 8),
  '9\/12': calc(#{$column-width-12} * 9),
  '10\/12': calc(#{$column-width-12} * 10),
  '11\/12': calc(#{$column-width-12} * 11)
);

$font-sizes: (
  8: 0.5rem,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  22: 1.375rem,
  24: 1.5rem,
  26: 1.625rem,
  28: 1.75rem,
  30: 1.875rem,
  36: 2.25rem,
  40: 2.5rem,
  44: 2.75rem,
  50: 3.125rem,
  52: 3.25rem
);
