.edit-teams {
  @include gray-scrollbar;
  & .modal-content {
    width: 35rem !important;
  }
  & .edit-team-modal {
    padding: 2rem 3.125rem 0rem 3.125rem;
    width: 35rem !important;
  }
  & .modal-header-text {
    font-style: normal;
    letter-spacing: -0.06rem;
  }
  & .modal-subheader {
    line-height: 1.125;
    font-style: normal;
    letter-spacing: 0.01rem;
  }
  & .team-name-text {
    margin-bottom: 0.375rem;
    font-size: 0.5rem;
    line-height: 0.625rem;
    font-style: normal;
    letter-spacing: 0.2rem;
  }
  & .modal-footer {
    justify-content: space-between !important;
    padding: 0rem 3.125rem 2rem 3.125rem !important;
    border-top: none !important;
  }
  & .add-team:hover {
    rect {
      fill-opacity: 1;
    }
  }
  & .inputs-overflow {
    max-height: 30vh !important;
    overflow-y: scroll;
  }
}

.input-wrapper {
  border: 1px solid darkgrey;
  & input:disabled {
    background-color: transparent !important;
  }
  &.input-confirm {
    background: rgba(167, 24, 24, 0.15) !important;
    border: 1px solid #b22222 !important;
  }
  & .team-input {
    flex-grow: 2;
    border: none !important;
    &:focus {
      outline: none !important;
    }
  }
}

.team-manage-links {
  & .team-delete-link {
    color: #a71818 !important;
    font-size: 0.5rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 0.625rem;
    letter-spacing: 0.1rem;
    padding-right: 0.75rem !important;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
      font-weight: 700 !important;
    }
  }
  .team-cancel-link {
    color: #000000 !important;
    font-size: 0.5rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 0.625rem;
    letter-spacing: 0.1rem;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
      font-weight: 700 !important;
    }
  }
}
.org-filters {
  width: 146px;
}
.vertical-align-initial {
  vertical-align: initial;
}
.custom-modal {
  & .modal-body {
    padding: 70px 78px !important;
  }
  & .modal-content {
    background: #f3f6f6 !important;
    border: 1px solid #000000 !important;
    border-radius: 10px !important;
  }
  & .modal-dialog {
    max-width: 480px;
  }
}
.monthly-payments {
  padding-left: 0.75rem !important;
}
.org-tabs-wrapper {
  margin: 32px 0px 16px 0px;
}
.create-new-btn {
  margin-top: 37px;
  width: 213px;
}
.coach-rates-text {
  font-family: 'Open Sans';
  font-size: 0.75rem;
  font-weight: 500;
  font-style: normal;
  line-height: 0.875rem;
  color: #000;
}
.coach-payment-rates {
  & .modal-body {
    padding: 33px 47px 48px 47px !important;
  }
}
.sow-deactivation {
  & .modal-body {
    padding: 32px 66px 50px 65px !important;
  }
  & .title {
    margin-bottom: 10px;
  }
  & .description {
    margin-bottom: 13px;
  }
  & .sow-deactivation-input {
    margin-bottom: 23px;
  }
  & .confirm-btn {
    margin-left: 29px;
  }
}
.org-users-filter {
  width: 146px;
  height: 28px;
  & .search-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none;
    &:focus {
      border-color: #000 !important;
      box-shadow: none !important;
    }
  }
  & .input-group-text {
    color: transparent !important;
    background-color: transparent !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none;
  }
}
.no-users {
  margin-bottom: 32px;
}
.invite-link-btn {
  margin-top: 12.8px;
}
.deactiveate-sow,
.deactiveate-group {
  position: absolute;
  bottom: 38px;
  width: 90%;
}
.deactiveate-group {
  left: 16px;
}
.team-btn {
  height: 31px;
  padding: 0 20px;
}
.custom-coaching-rate {
  & .modal-body {
    padding: 38px 68px 37px 67px !important;
  }
}
.invite-link {
  margin-top: 35px;
  width: 60%;
}
.plan-dropdown {
  width: 150px;
  margin-bottom: 12px;
}
.font-color {
  margin: 0 12px;
  border-left: 2px solid #000000;
}
.ml-12px {
  margin-left: 12px;
}
.upload-logo:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.preview-btn {
  font-weight: 600 !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
  height: 30px;
}
.d-flex-unset {
  display: unset !important;
}
.create-sow-btn {
  width: 145px;
}
.sow-details-btn {
  width: 121px;
  padding: 8px 15px;
}
.create-new-group {
  margin-top: 37px;
}
.coach-rates {
  & input {
    color: #000;
    border-radius: 3px !important;
  }
}
.preview-modal-wrapper {
  & .modal {
    padding-left: 0px !important;
  }
}
.preview-modal {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  & .close-icon {
    position: absolute;
    right: 3%;
    top: 3%;
    z-index: 10;
  }
  & .modal-content {
    border: none !important;
  }
}
.top-25-pr {
  top: 25%;
}
.font-color-checkboxes {
  & label {
    font-family: Open Sans !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    text-transform: capitalize !important;
    color: #000 !important;
  }
  .custom-checkbox .custom-control-input ~ .custom-control-label::after {
    border-radius: 4px !important;
    border: 1px solid #000 !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .custom-checkbox .custom-control-input ~ .custom-control-label::before {
    border-radius: 4px !important;
    border: 1px solid #000 !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    opacity: 1 !important;
    background-color: #000 !important;
    border: 1px solid transparent !important;
  }
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    opacity: 1 !important;
    background-color: #000 !important;
    border: 1px solid transparent !important;
  }
}
.remove-coaches {
  & .modal-body,
  .modal-footer {
    padding: 2rem !important;
  }
}
.border-modal {
  & .modal-content {
    background: #f3f6f6 !important;
    border: 1px solid #000000 !important;
    border-radius: 10px !important;
  }
}
.color-palette-wrapper {
  padding: 10px;
  background: map-get($gray, '104');
  border: 0;
  border-radius: 5px;
  width: 315px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.no-users-text {
  margin-bottom: 41px;
}
.upload-csv {
  margin-top: 62px;
}
.csv-error {
  margin-bottom: 28px;
}
.custom-progressbar {
  height: 32px;
  border: 1px solid #000;
  & .filled-progressbar {
    height: 30px;
  }
}
.add-individual-user {
  & .modal-dialog {
    max-width: 560px !important;
  }
  & .modal-body {
    padding: 31px 55px 40.35px 31px !important;
  }
  & .add-user-input {
    margin-bottom: 18.26px;
  }
  & .add-user-btn {
    margin-top: 19.74px;
    padding: 11px 36px;
  }
}
.csv-close-btn {
  margin-top: 28px;
}
.bg-sow-header {
  background-color: map-get($blue, '300');
}
.bg-sow-group-header {
  background-color: #fff5d4;
}
.edit-coach {
  margin-right: 15px;
}
.this-template-link {
  text-decoration: underline !important;
  color: map-get($blue, '400') !important;
  &:hover {
    color: map-get($blue, '400') !important;
  }
}
