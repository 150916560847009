@import '../mixin.scss';

.profile-subheader {
  font-style: normal;
  letter-spacing: -0.04em;
}

.client-plans {
  .userinfo-select-plan {
    border-color: #000 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .planend-datepicker {
    background-color: #fff;
    border: 1px solid #000 !important;
    font-family: Open Sans;
    font-weight: 600;
    border-radius: 5px !important;
    padding: 6px 13px;
    height: 32px;
    &:disabled,
    &:hover:disabled {
      pointer-events: none;
      border: 1px solid darkgrey !important;
    }
  }
}

.delete-link {
  color: #a71818 !important;
}

.p-inline-15 {
  padding-inline-start: 0.938rem !important;
}
.navtab-header {
  margin-top: 32px;
}
.client-basic-info {
  margin-top: 12px;
}
.green-btn {
  width: 157px;
  padding: 9px 0px;
  text-align: center;
  background: #509f92;
  border-radius: 3px;
  color: #fff !important;
}
.normal-btn {
  width: 157px;
  padding: 9px 0px;
  text-align: center;
  background: transparent;
  border-radius: 3px;
  color: #000;
  border: 1px solid #000000;
}
.client-sows {
  & .title {
    margin-bottom: 12px;
  }
}
.add-session-btn {
  outline: none !important;
  color: map-get($saffron, '700') !important;
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    font-weight: 700 !important;
  }
}
.confirmation-btn {
  width: 128.67px;
}
.delete-session {
  padding-right: 44px !important;
}
.client-sessions,
.client-analytics {
  & tr:hover {
    & .date-link {
      text-decoration: underline;
    }
  }
}
.analytics-links {
  padding-right: 22px !important;
}
.update-email {
  margin-top: 32px;
}
.ethnicity__value-container {
  height: auto !important;
  min-height: 39px !important;
  padding: 0px 10px !important;
  &.bg-white-container {
    @include form-control-lg('#fff');
  }
  &.bg-gray-container {
    @include form-control-lg(map-get($gray, '400'));
  }
}
.growth-plan-btn {
  margin-top: 3.625rem;
  padding: 12px 30px;
}
.profile-tabs {
  @include tabs-arrows-style(
    14px,
    -1rem,
    0.875rem
  ); // $top, $left, $mt (margin-top)
}

.custom-merge-modal {
  & .modal-content {
    background: #f3f6f6 !important;
    border: 1px solid #000000 !important;
    border-radius: 10px !important;
  }
  & .modal-dialog {
    max-width: 480px;
  }
}
