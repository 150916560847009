@import './mixin.scss';

.btn-saffron {
  line-height: normal !important;
  @include btn-variant(
    map-get($saffron, '300'),
    map-get($saffron, '600'),
    map-get($saffron, '600')
  );
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
}

.btn-black {
  line-height: normal !important;
  @include btn-variant(
    map-get($dark, '900'),
    map-get($dark, '800'),
    map-get($dark, '600')
  );
  &:hover:enabled {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
  }
}

.btn-module {
  background-color: map-get($dark, '900');
  color: white;
  letter-spacing: 1px;
  text-transform: capitalize;
  &:hover {
    background-color: map-get($dark, '900') !important;
    color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3) !important;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: white;
    opacity: 0.4;
  }
}

.btn-outline-white {
  color: $white;
  border-color: $white;
  &:focus {
    color: $white;
  }
}

.btn-spinner {
  vertical-align: middle;
}

.btn-gray {
  line-height: normal !important;
  @include btn-variant(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.2)
  );
}
.btn-gray-sm {
  letter-spacing: 1px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  line-height: normal !important;
}
.btn-custom {
  border: 1px solid #000000;
  padding: 0.625rem 1.5rem;
  font-family: 'Montserrat', 'Open Sans';
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.75rem;
  letter-spacing: 0.25rem;
  text-align: center;
}

.btn-outline-dark {
  color: $black !important;
  border: 1px solid #000000;
  &:hover {
    background-color: $black !important;
    border: 1px solid #000000;
    color: $white !important;
  }
}
.btn-danger {
  line-height: normal !important;
  @include btn-variant(
    map-get($red, '800'),
    map-get($red, '800'),
    map-get($red, '800')
  );
}
.secondary-popup-button {
  color: $black !important;
  border: 1px solid #000000;
  background-color: #fff !important;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.25em;
  padding: 8px 30px;
  text-transform: uppercase;
  font-weight: 600;
}
