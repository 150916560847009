.onboard {
  max-width: 400px;
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.login-error {
  border-radius: 0;
  background-color: rgba(map-get($red, '600'), 0.5);
}

// for 1k
.onboard {
  max-width: 424px;
  .custom-control-input {
    top: 0.5rem;
    z-index: 2;
  }
  .custom-control-input ~ .custom-control-label::before {
    border-width: 0px;
  }

  > .row {
    height: 100vh !important;
    min-height: 720px;
  }

  .s2 {
    margin: 12px 0 12px;
  }

  .s5 {
    margin-bottom: 20px;
  }

  .name-fields {
    .form-group {
      width: calc(50% - 20px);
    }
  }

  [role='combobox'] {
    .form-group {
      margin-bottom: 12px;
    }
  }

  .form-group {
    margin-bottom: 32px;

    > small {
      font-size: 10px;
      line-height: 16px;
      padding: 0px;
      margin: 0px;
    }

    .password-visibility-icon {
      top: calc(50% + 7px) !important;
      transform: translateY(-50%);
    }

    input[type='checkbox'] {
      + label {
        color: black !important;
        font-size: 10px !important;
        font-weight: 500;
        line-height: 22px;

        &:before {
          border: 1px solid black;
          border-radius: 2px;
        }
      }
    }

    > span > div {
      flex-direction: column;
      align-items: stretch !important;

      > label {
        margin-bottom: 4px;
        font-size: 8px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0.2em;
        display: block;
      }

      > input {
        border: 1px solid #000000;
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        height: 32px;
        border-radius: 2px;
        color: black;
        padding: 0 8px;

        &[type='email'] {
          text-decoration: underline;
        }

        &.is-invalid {
          border: 1px solid #b22222;
        }
      }
    }
  }

  .btn {
    width: auto;
    height: 32px;
    padding: 0 20px;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.2em;

    + div {
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;

      a span {
        color: #bf9000;
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .onboard {
    max-width: 525px;
  }
}

// for 2k
@media screen and (min-width: 2360px) {
  .onboard {
    max-width: 848px;

    .s2 {
      font-size: 3rem !important;
      line-height: 3.625rem !important;
      margin: 24px 0 16px;
    }

    .s5 {
      font-size: 1.25rem !important;
      line-height: 2.125rem !important;
      margin-bottom: 48px;
    }

    .s7 {
      font-size: 1rem !important;
      line-height: 1.75rem !important;
    }

    .s8 {
      font-size: 0.875rem !important;
      line-height: 1.5rem !important;
    }

    .dropdown-menu .text-error {
      font-size: 16px !important;
    }

    [role='combobox'] {
      .form-group {
        margin-bottom: 24px;
      }
    }

    .form-group {
      margin-bottom: 32px;

      > small {
        font-size: 20px;
        line-height: 32px;
      }

      .custom-control {
        padding-left: 48px;
      }

      .password-visibility-icon {
        top: calc(50% + 21px) !important;
        transform: scale(1.5) translate(-50%, -50%);
      }

      input[type='checkbox'] {
        + label {
          font-size: 20px !important;
          line-height: 44px !important;

          &::before,
          &::after {
            height: 24px;
            width: 24px;
            top: 10px;
            left: -48px;
          }
        }
      }

      > span > div {
        > label {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
        }

        > input {
          border: 1px solid #000000;
          font-size: 24px;
          height: 64px;
          line-height: 30px;
          padding: 0 16px;
        }
      }
    }

    .btn {
      font-size: 20px !important;
      line-height: 24px !important;
      height: 64px;
      padding: 0 40px;

      + div {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

// for 4k
@media screen and (min-width: 3800px) {
  .onboard {
    max-width: 1272px;

    .s2 {
      font-size: 4.5rem !important;
      line-height: 5.438rem !important;
      margin: 36px 0 24px;
    }

    .s5 {
      font-size: 1.875rem !important;
      line-height: 3.125rem !important;
      margin-bottom: 80px;
    }

    .s7 {
      font-size: 1.25rem !important;
      line-height: 2rem !important;
    }

    .s8 {
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }

    [role='combobox'] {
      .form-group {
        margin-bottom: 36px;
      }
    }

    .dropdown-menu .text-error {
      font-size: 24px !important;
    }

    .form-group {
      margin-bottom: 64px;

      > small {
        font-size: 30px;
        line-height: 48px;
      }

      .custom-control {
        padding-left: 72px;
      }

      .password-visibility-icon {
        top: calc(50% + 35px) !important;
        transform: scale(2) translate(-50%, -50%);
      }

      input[type='checkbox']n {
        + label {
          font-size: 30px !important;
          line-height: 66px !important;

          &::before,
          &::after {
            height: 36px;
            width: 36px;
            top: 20px;
            left: -72px;
          }
        }
      }

      > span > div {
        > label {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 12px;
        }

        > input {
          font-size: 36px;
          height: 96px;
          line-height: 45px;
          padding: 0 24px;
        }
      }
    }

    .btn {
      font-size: 30px !important;
      line-height: 36px !important;
      height: 96px;
      padding: 0 60px;

      + div {
        font-size: 30px;
        line-height: 48px;
      }
    }
  }
}

.banner-img {
  margin-left: -10%;
}

@include media-breakpoint-up(lg) {
  .login-error-wrapper {
    min-height: 4.5rem;
  }
  .password-fields {
    .password {
      width: calc(50% - 20px);
    }
  }
}

.forgot-password-card,
.onbording-card {
  background-color: rgba(245, 245, 245, 0.85);
}

.highcharts-credits {
  display: none !important;
}

.login-custom-card {
  padding: 1.25rem 0rem !important;
}
.onboarding-content {
  background: #f3f6f6;
}
.onboarding-card {
  max-width: 600px;
  margin: 0 auto;
}
.team-dropdown {
  border: 1px solid #000000;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.938rem;
  color: black;
  padding: 0 0.5rem;
}
.signup-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  // width: 150px;
  // height: 150px;
}
