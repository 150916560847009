.assessment-video {
  border-radius: 10px;
  object-fit: fill;
  aspect-ratio: 16 / 9;
  background-color: lightgray;
}
.score-card {
  -webkit-animation: filler 2s ease-in-out;
  -moz-animation: filler 2s ease-in-out;
  animation: filler 2s ease-in-out;
}

@-webkit-keyframes card-score-filler {
  0% {
    height: 0;
  }
}
@-moz-keyframes card-score-filler {
  0% {
    height: 0;
  }
}
@keyframes card-score-filler {
  0% {
    height: 0;
  }
}

.category-percentage {
  @extend .s1;
  color: #fff;
  bottom: 10%;
  left: 50%;
  transform: translateY(-20%) translateX(-50%);
}

.direct-reports {
  background-color: #0a223c;
  border-radius: 0.625rem;
  padding: 2.5rem 3.875rem;
  color: #fff;
}

.subcategory {
  padding: 1.188rem;
  border-bottom: 1px solid #d9d9d9;
}

.info-tooltip {
  cursor: pointer;
  z-index: 10 !important;
  & .tooltip-arrow {
    display: none !important;
  }
  & .tooltip-inner {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 0.75rem; // 12px
    line-height: normal;
    letter-spacing: 0rem;
    padding: 1rem !important;
    border-radius: 0.625rem !important;
  }
}
.text-manager-efficacy {
  color: #84a07c;
}

.awareness-gap {
  & .title-wrapper {
    margin-bottom: 1.199rem;
  }
  & .highcharts-background {
    fill: none !important;
  }
  & .highcharts-credits {
    display: none !important;
  }
}

.leader-select {
  & .leader-select-options-wrp {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  & .icon-left-arrow {
    transform: rotate(270deg);
  }
  & .ab-select__control {
    cursor: pointer;
  }
  & .ab-select__placeholder {
    color: #000;
    @extend .s7;
  }
  & .ab-select__value-container {
    padding: 0.5rem 0.625rem !important;
  }
  & .report-loader {
    width: 0.7rem !important;
    height: 0.7rem !important;
  }
}
.manager-result-compare-circle {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 100%;
  margin: 0 auto;
  &.small-size-circle {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
}

.manager-result-compare-square {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  margin: 0 auto;
  &.small-size-square {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
}

.compare-subcatgory-table {
  th {
    padding-bottom: 1.125rem !important;
  }
  td {
    padding-top: 1.125rem !important;
    padding-bottom: 1.125rem !important;
    font-size: 1rem !important;
    line-height: 1.625rem !important;
    letter-spacing: -0.02rem !important;
  }
}

.bg-blue-manager {
  background: #4978ab;
}
.text-blue-manager {
  color: #4978ab !important;
}

.overflow-category-name {
  overflow-x: visible;
  white-space: nowrap;
}

.bg-light-blue-manager {
  background: #69b1d8;
}

.text-light-blue-manager {
  color: #69b1d8 !important;
}

.box-content {
  border: 1.5px solid #000;
  padding-left: 1rem;
  padding-right: 1.563rem;
  border-radius: 0.313rem;
  background-color: #f3f6f6;
  & .category-details {
    padding: 1.125rem 0rem;
    border-bottom: 1.5px solid #d9d9d9;
  }
}
.text-orange-100 {
  color: #ce8147;
}
.previous-result {
  & .shimmer-table-row--header {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.25rem;
  }
  & .previous-result-title {
    font-family: 'Open Sans';
    font-size: 1.25rem; //20px
    font-style: normal;
    font-weight: 600;
    line-height: 2.125rem; //34px
    letter-spacing: -0.025rem; //-0.4px
  }
  & .result-link {
    @extend .s6;
    padding: 1.125rem 0rem;
  }
  .result-link {
    border-bottom: 1px solid #d9d9d9;
  }
  .first-result-link {
    border-top: 1px solid #d9d9d9;
  }
  & .form-check-input {
    height: 1rem;
    width: 1rem;
    border-color: #000;
    border-radius: 3px;
    cursor: pointer;
    &:checked {
      border-color: #0d6efd;
    }
  }
}
.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.assessment-circle {
  display: block;
  margin-right: 0;
}
.assessment-score {
  display: inline-block;
  width: 100%;
}
.courses-title-row {
  &.first-row {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  align-items: center;
  color: #000;
  &:hover {
    background: #f3f6f6;
    border-bottom: 1px solid transparent;
    border-radius: 0.313rem;
    & .hover-text-bold {
      font-weight: 700;
    }
  }
}
.border-bottom-none {
  border-bottom: none !important;
}
.border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
}
.first-row:hover,
.first-result-link:hover,
.first-media-info:hover {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.result-link:hover {
  background: #f3f6f6;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  border-radius: 0.313rem;
}
.assessment-result-link:hover {
  text-decoration: none !important;
}
.category-details:hover,
.result-link:hover {
  & .hover-text-bold {
    font-weight: 700;
  }
}
.hover-text-bold {
  font-weight: 600;
}
.courses-title {
  flex-basis: 70%;
}
.requests-sent-tooltip {
  opacity: 1 !important;
  z-index: 1200 !important;
  border-radius: 0.625rem;
  & .tooltip-arrow {
    display: none !important;
  }
  & .tooltip-inner {
    padding: 1.625rem 1.938rem 1.625rem 1.875rem !important;
    border: 2px solid #000 !important;
    background: #fff !important;
    max-width: 23rem;
    width: 100%;
  }
}
.mediabar-tooltip {
  opacity: 1 !important;
  & .tooltip-arrow {
    display: none;
  }
  & .tooltip-inner {
    text-align: left !important;
    background: #f3f6f6;
    border-radius: 5px;
    border: 1.5px solid #0a223c;
    max-width: 20rem;
    @extend .s7;
    padding: 1.5rem;
  }
}
.requested-user-details {
  font-family: Open Sans;
  font-size: 0.875rem; //14px
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; //24px
  letter-spacing: -0.018px; //-0.28px
  word-break: break-all;
}
.requests-user-modal {
  @include gray-scrollbar;
}
.requests-sent-body {
  max-height: 27.5rem;
  overflow: overlay !important;
  overflow: scroll;
}
.result-active-link,
.result-active-link:hover {
  color: #bf9000;
}
.learn-more-link:hover {
  font-weight: 700;
  text-decoration: none !important;
}
.score-text {
  @extend .s1;
  color: #fff;
  font-size: 4.125rem !important;
}
.assessment-info-box {
  border: 1.5px solid #000;
}
.wisdom-assessment {
  .s1 {
    font-family: 'Cormorant Garamond' !important;
    font-weight: 500 !important;
    font-size: 2.75rem !important;
    line-height: 3.5rem !important;
    letter-spacing: -0.11rem !important;
  }
  .s2 {
    font-family: 'Cormorant Garamond' !important;
    font-weight: 500 !important;
    font-size: 2.375rem !important;
    line-height: 3.25rem !important;
    letter-spacing: -0.095rem !important;
  }
  .category-percentage {
    font-family: 'Cormorant Garamond' !important;
    font-weight: 500 !important;
    font-size: 2.75rem !important;
    line-height: 3.5rem !important;
    letter-spacing: -0.11rem !important;
  }
  .s11 {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0rem;
  }
  .mb-52px {
    margin-bottom: 3.25rem !important;
  }
  .mb-18px {
    margin-bottom: 1.125rem;
  }
}
@include media-breakpoint-up(lg) {
  .mr-lg-12px {
    margin-right: 0.75rem;
  }
  .ml-lg-12px {
    margin-left: 0.75rem;
  }
  .ml-lg-36px {
    margin-left: 2.25rem;
  }
  .mr-lg-36px {
    margin-right: 2.25rem;
  }
  .assessment-chart {
    display: inline-block;
    margin-right: 34.78pt;
  }
  .assessment-score {
    display: inline-block;
    width: 100%;
  }
}
@include media-breakpoint-up(sm) {
  .assessment-video {
    max-height: 18rem;
  }
}
@include media-breakpoint-down(lg) {
  .direct-reports {
    padding: 1.5rem 1rem;
  }
  .leader-select {
    width: 75%;
  }
  .mb-52px {
    margin-bottom: 2.5rem !important;
  }
}
