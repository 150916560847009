.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .bar-progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 16px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #6f6f6f;

    .bar-progress-knob {
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: -8px;
    }
    &:hover {
      .bar-progress-knob {
        background-color: white;
        border: 1.5px solid white;
      }
    }
    &.disabled {
      cursor: none !important;
      background: linear-gradient(
        to right,
        #f6f6f6 8%,
        #f0f0f0 18%,
        #f6f6f6 33%
      );
      animation: shimmer 2.2s linear infinite forwards;
    }
  }
}

.vol-bar-root {
  display: inline-flex;
  width: 10rem;
}

.bar-vol {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .bar-volume {
    flex: 1;
    border-radius: 5px;
    margin-left: 16px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #6f6f6f;

    .bar-volume-knob {
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: -8px;
    }
    &:hover {
      .bar-volume-knob {
        background-color: white;
        border: 1.5px solid white;
      }
    }
  }
}

.bar-time {
  margin: 0 12px;
}

.mini-playbar {
  flex: 1;
  min-width: 0;
  .title-shimmer {
    min-width: 200px;
  }
  .title-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
