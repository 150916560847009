.right-sidebar-overlay {
  transition: 0.5s;
  background: #000;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 41;
  &.show-overlay {
    opacity: 0.2;
    visibility: visible;
  }
  &.hide-overlay {
    opacity: 0;
    visibility: hidden;
  }
}

.right-sidebar-wrapper {
  @include gray-scrollbar;
  & .right-sidebar-content {
    margin-left: 0rem;
    letter-spacing: 0.02rem;
    overflow-y: scroll;
    overflow-y: overlay !important;
    -ms-overflow-style: none; /* IE and Edge */
    z-index: 98;
    position: fixed !important;
    bottom: 0;
    right: 0;
    transition: 0.5s;
    transform: translate(0%, 0%);
  }
  & .hide-sidebar {
    transform: translate(100%, 0%);
  }
  & .sidebar-label {
    font-family: Montserrat;
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    letter-spacing: 0.25em;
  }
}
.copy-btn {
  &:focus,
  :active {
    box-shadow: none !important;
    outline: none !important;
  }
}

.team-fields {
  & .viewonly:disabled {
    background-color: #fff !important;
    height: 2rem !important;
  }
}
.additional-settings {
  & ul {
    padding-inline-start: 30px !important;
  }
}
.action-button {
  padding-top: 0.82rem;
  padding-bottom: 0.82rem;
  padding-left: 1.875rem;
}
.inviteonly-content-text {
  z-index: 1036 !important;
}
.w-80-pr {
  width: 80%;
}
.header-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-transform-none {
  text-transform: none !important;
}
