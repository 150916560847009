@mixin pointer-cursor($cursor: pointer) {
  cursor: $cursor;
}

@mixin transition($args...) {
  @each $arg in $args {
    -webkit-transition: $arg;
    -moz-transition: $arg;
    -ms-transition: $arg;
    -o-transition: $arg;
    transition: $arg;
  }
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin background-opacity($color, $opacity: 1) {
  background: $color !important;
  background: rgba($color, $opacity) !important;
}

@mixin transition-all {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@mixin text-opacity($color, $opacity: 1) {
  color: $color !important;
  color: rgba($color, $opacity) !important;
}

@mixin touch-hover() {
  .no-js &:hover, // the fallback
  .no-js &:focus,
  .js.no-touch &:hover, // enhanced for no-touch
  .js.no-touch &:focus,
  .js.touch &:active {
    // relay same styles to active for touch devices
    @content;
  }
}

@mixin hover {
  .no-touch & {
    &:hover {
      @content;
    }
  }
}

@mixin input-icon {
  right: 10px;
  z-index: 1;
  cursor: pointer;
}

@mixin gray-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: 8px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}
@mixin progress-checkboxes {
  height: 0.75rem;
  width: 0.75rem;
  top: 0.35rem;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

@mixin hide-progress-checkboxes {
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}

@mixin progress-checkboxes-checked($color) {
  background-image: none !important;
  background-color: $color !important;
  border: 1px solid $color !important;
  border-radius: 3px;
}

@mixin page-tabs(
  $black,
  $fontWeight,
  $padding,
  $fontFamily,
  $bgColor,
  $lineHeight,
  $fontSize,
  $letterSpacing
) {
  color: $black;
  padding: $padding;
  text-align: center;
  border-radius: 1.245rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  font-weight: $fontWeight;
  font-family: $fontFamily;
  background-color: $bgColor;
  line-height: $lineHeight;
  font-size: $fontSize;
  letter-spacing: $letterSpacing;
  &:focus-visible,
  &.active {
    outline: none !important;
  }
  .nav-link-icon {
    @include transition-all;
    display: none;
  }
}

@mixin btn-variant($bg, $bg-hover, $bg-disabled) {
  @extend .s8;
  background-color: $bg;
  color: white;
  letter-spacing: 0.25rem;
  text-align: center;
  border-radius: 5px;
  padding: 8px 30px;
  &:hover {
    background-color: $bg-hover;
    color: white;
  }
  &:disabled {
    background-color: $bg-disabled;
    color: white;
  }
}

@mixin swiper-buttons($top) {
  .swiper-button-prev {
    width: 1rem !important;
    height: 1.438rem !important;
    top: $top !important;
    &::after {
      content: none;
      display: none;
    }
  }

  .swiper-button-next {
    width: 1rem !important;
    height: 1.438rem !important;
    top: $top !important;
    &::after {
      content: none;
      display: none;
    }
  }
}

@mixin tabs-horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@mixin tabs-arrows-style($top, $left, $mt) {
  .tab-button-next {
    position: absolute;
    right: -1rem;
    left: auto;
    top: $top;
  }
  .tab-button-prev {
    position: absolute;
    right: auto;
    left: $left;
    margin-top: $mt;
    transform: rotate(180deg);
  }
}
@mixin common-btn-style {
  line-height: 0.625rem !important;
  letter-spacing: 0.1rem !important;
}

@mixin bottom-card-icons($circle1, $circle2, $circle3) {
  & .circle-1 {
    fill: $circle1;
  }
  & .circle-2 {
    fill: $circle2;
  }
  & .circle-3 {
    fill: $circle3;
  }
}

@mixin customize-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #b3b3b3;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #000;
  }
}

@mixin hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@mixin media-list-style($max-height) {
  list-style: none !important;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2) !important;
  overflow-y: scroll;
  max-height: $max-height;
  background: ghostwhite;
  li {
    padding: 3px;
    &:hover {
      background-color: #428bca !important;
      cursor: pointer;
    }
  }
}

@mixin header-icon-styles($opacity, $fill) {
  &:hover {
    path {
      opacity: $opacity !important;
    }
  }
  &:active {
    path {
      fill: $fill !important;
    }
  }
  &.active {
    path {
      fill: map-get($saffron, '300');
      opacity: 1 !important;
    }
  }
}

@mixin search-input($width, $max-height) {
  list-style: none !important;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2) !important;
  overflow-y: overlay;
  max-height: $max-height;
  position: absolute;
  width: $width;
  background: ghostwhite;
}

@mixin custom-checkbox() {
  .header-checkbox.custom-checkbox
    .custom-control-input
    ~ .custom-control-label::after {
    border-radius: 4px !important;
    width: 17px !important;
    border: 1px solid #000 !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }
  .custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::after {
    background-color: #000 !important;
  }
  .header-checkbox.custom-checkbox
    .custom-control-input
    ~ .custom-control-label::before {
    border-radius: 4px !important;
    width: 17px !important;
    border: 1px solid #000 !important;
    background-color: transparent !important;
    opacity: 1 !important;
  }
  .custom-checkbox .custom-control-input ~ .custom-control-label::after {
    border-radius: 4px !important;
    width: 18px;
    height: 18px;
    border-color: rgba(0, 0, 0, 0.6);
  }
  .custom-checkbox .custom-control-input ~ .custom-control-label::before {
    border-radius: 4px !important;
    width: 18px;
    height: 18px;
    border-color: rgba(0, 0, 0, 0.6);
  }
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: none !important;
    opacity: 0.6;
    background-color: #000 !important;
    border: 1px solid transparent !important;
    background-image: none;
    border-radius: 4px;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    opacity: 0.6;
    border: 1px solid transparent !important;
  }

  .header-checkbox.custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    opacity: 1 !important;
  }
  .header-checkbox.custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    opacity: 1 !important;
  }
}
@mixin form-control-lg($bgColor) {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02em !important;
  padding: 8px 18px;
  border: 1px solid #000000;
  border-radius: 3px !important;
  background: $bgColor !important;
  color: #000 !important;
  &::placeholder {
    color: #000 !important;
  }
}

@mixin timeZoneField {
  height: auto !important;
  min-height: 39px !important;
  padding: 2px 18px !important;
}
@mixin custom-text-truncate($width) {
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  width: $width;
  overflow: hidden;
}
@mixin range-slider-thumb($bgColor) {
  background-color: $bgColor;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
