.well {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 30px;
  @include box-shadow(3px 3px 0 rgba(0, 0, 0, 0.05));
}

.coach-container {
  width: 230px;
  .coach {
    padding: 10px !important;
    height: 230px;
    width: 100%;
    margin-bottom: 1.625rem;
  }
}

.category-score-card {
  padding: 10px !important;
  height: 230px;
  width: 230px;
  margin: 0 auto;
  border-radius: 10px;
}
.leader-score-card {
  padding: 10px !important;
  height: 14rem;
  width: 19.313rem;
  margin: 0 auto;
  border-radius: 15px;
}

.gaugeSmaller {
  width: 100%;
  height: 150px;
  display: block;
  /* margin: 0 auto */
}

.category-score {
  font-family: 'Montserrat', 'Open Sans';
  font-weight: 400;
  color: #fff;
  font-size: 43px;
}

.seperate-percentage {
  color: #ffffff;
  font-size: 30px;
  font-family: 'Montserrat', 'Open Sans';
  font-weight: 400;
}

.category-title-label {
  margin: 10px 0;
  font-size: 15pt;
  color: #ffffff;
  text-transform: none !important;
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: -0.02rem;
}

.btn-overall-score {
  padding: 11px 24px;
  font-size: 6pt;
  border: 1px solid #000000;
  border-radius: 3px;
  text-decoration: none !important;
  font-weight: 600 !important;
  margin: 17px 0;
  display: inline-block;
  color: #646464 !important;
  text-transform: capitalize;
  line-height: 0.625rem;
  letter-spacing: 0.1rem;
  &:hover {
    background-color: #000;
    color: #fff !important;
  }
}

.explore-insight-title {
  font-size: 18pt;
  color: #000;
  font-family: 'Open Sans', 'Montserrat';
}

.explore-insight-description {
  font-family: 'Open Sans', 'Montserrat';
  font-size: 12pt;
  color: #000;
}

.assessment-circle {
  display: block;
  margin-right: 0;
}
.assessment-score {
  display: inline-block;
  width: 60%;
}
.assessment-score {
  display: block;
  width: 100%;
  padding: 0 30px;
}

@include media-breakpoint-up(lg) {
  .assessment-circle {
    display: inline-block;
    margin-right: 60pt;
  }
  .assessment-score {
    display: inline-block;
    width: 60%;
  }
}

.score {
  width: 185px;
  height: 185px;
  display: block;
}

.category-assessment-label {
  font-size: 15pt;
  font-family: 'Open Sans';
}

.self-awareness-courses-wrp,
.team-cohesion-courses-title-wrp,
.vision-impact-courses-title-wrp {
  font-size: 13pt;
}

.self-awareness-courses-lable,
.team-cohesion-courses-lable,
.vision-impact-courses-lable {
  font-size: 12pt;
  padding: 8px 0 8px 0;
  font-weight: 700;
  border-bottom: 1px solid #000;
}

.self-awareness-courses-title-row,
.team-cohesion-courses-title-row,
.vision-impact-courses-title-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  align-items: center;
  cursor: pointer;
  color: #000;
}

.survey-subcat-link {
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    .info-icon {
      fill: black;
      & .info-circle {
        fill: white;
      }
      & rect {
        fill: white;
      }
    }
  }
}

.courses-title {
  flex-basis: 70%;
}

.course-title-span {
  font-family: 'Open Sans';
  font-size: 10.5pt;
}

.category-total-score {
  font-family: 'Montserrat', 'Open Sans';
  font-size: 50px;
  font-weight: 400;
}

.percentage-icon {
  font-size: 35px;
  font-family: 'Montserrat', 'Open Sans';
  font-weight: 400;
}

// Survey dashboard
.survey-page {
  & {
    .custom-checkbox .custom-control-input ~ .custom-control-label::after,
    ::before {
      width: 12px !important;
      height: 12px !important;
    }
    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::after,
    ::before {
      width: 12px !important;
      height: 12px !important;
    }
  }
  .survey-result {
    .nav-tabs {
      margin-bottom: 2.5rem;
      border: none;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    .analytics-tabs {
      @include tabs-horizontal-scroll;
      & .nav-link {
        @include page-tabs(
          $black,
          600,
          0.9rem 1rem,
          'Montserrat',
          map-get($gray, '104'),
          0.762rem,
          0.625rem,
          0.25rem
        );
        text-transform: uppercase;
        &.active,
        &:hover {
          color: $white;
          background-color: map-get($saffron, '300');
          font-weight: 700;
        }
      }
    }

    .accordion .card .card-header {
      padding: 8px 12px;
    }

    .table {
      tbody {
        tr {
          td {
            .custom-checkbox {
              &.custom-control {
                min-height: 0.75rem;
              }
            }

            .right-aligned.form-group > div {
              text-align: right;
            }

            input[type='checkbox'] {
              + label {
                &::after,
                &::before {
                  left: -8px;
                }
              }
            }
          }
        }
      }
    }

    .assessment-actions {
      margin-top: 2rem;
    }
  }
}

.assessment-actions {
  & .compare-assessments {
    margin-bottom: 2rem;
  }
}

// Survey compare
.result-category-compare-panel {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.result-category-compare-label {
  font-size: 23pt;
}

.assessment-date {
  max-width: 100px;
}

//leader-assessment
.leader-assessment {
  &.manager-360-modal {
    & .la-input-group {
      overflow: visible !important;
      max-height: 100% !important;
    }
  }
  & .title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: -0.04rem !important;
  }
  & .preview-email {
    border: 1px solid #000;
    padding: 1.5rem 1.375rem 1.75rem 1.438rem;
  }
}
.assessment-btn {
  @include page-tabs(
    $black,
    600,
    0.875rem 1.5rem,
    'Montserrat',
    map-get($gray, '104'),
    0.762rem,
    0.625rem,
    0.25rem
  );
  margin-bottom: 0px !important;
  &:hover {
    color: $white;
    background-color: map-get($saffron, '300');
    font-weight: 700;
  }
  &:focus-visible,
  &:focus,
  &.active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:disabled {
    background-color: map-get($gray, '104') !important;
    color: #000 !important;
    cursor: not-allowed !important;
    &:hover {
      font-weight: 600 !important;
    }
  }
}

.send-request-btn {
  background-color: map-get($saffron, '300');
  color: white;
  letter-spacing: 0.2rem;
  text-align: center;
  font-size: 0.563rem;
  line-height: 0.688rem;
  font-family: 'Montserrat';
  text-transform: uppercase;
  border-radius: 0.188rem !important;
  font-weight: 600;
  padding: 0.59rem 1.813rem 0.59rem 1.875rem;
  &:hover {
    background-color: map-get($saffron, '700');
    color: white;
  }
  &:disabled {
    pointer-events: none;
    background-color: map-get($dark, '900-4');
  }
}

@include media-breakpoint-down(lg) {
  .assessment-date {
    max-width: 50%;
  }
  .leader-assessment {
    & .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.625rem;
      letter-spacing: 0.01rem;
    }
    & .preview-email {
      padding: 1.5rem 1.125rem 1.375rem 1.188rem;
    }
  }
}

.result-compare-circle {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin: 0 auto;
}
.manager-result-compare-circle {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 100%;
  margin: 0 auto;
  &.small-size-circle {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
}
.manager-result-compare-square {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 0;
  margin: 0 auto;
  &.small-size-square {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    min-height: 1rem;
  }
}

.comparison-table {
  font-size: 13pt;
  font-family: 'Open Sans';
  &.table {
    th {
      vertical-align: top;
    }
    tbody {
      font-weight: 300;
      & tr:hover {
        background-color: #f3f6f6 !important;
      }
    }
  }
}
.assessment-link {
  color: #000000 !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.new-assessment-details {
  & .assessment-header {
    line-height: 1.875rem;
    letter-spacing: -0.02rem;
    margin-top: 2.5rem;
  }
  & .assessment-description {
    letter-spacing: -0.02rem;
    color: rgba(0, 0, 0, 0.5);
  }
}

.personal-assessment {
  @include customize-scrollbar;
  table tbody {
    display: block;
    max-height: 17.938rem !important;
    overflow-y: overlay;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  & .table-responsive {
    border-bottom: none !important;
  }

  .border-bottom-none {
    border-bottom: none !important;
  }
}

.tbl-position-relative {
  & .table-responsive {
    position: relative !important;
  }
  & .custom-table:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 97% !important;
    border-bottom: 1px solid #000;
  }
}

.tbl-border-bottom {
  border-bottom: 1px solid #000 !important;
}

.mb-2-5 {
  margin-bottom: 2.5rem;
}

.category-chart-section {
  margin-bottom: 0.938rem;
}

@include media-breakpoint-up(lg) {
  .assessment-actions {
    margin-top: 2rem;
    & .compare-assessments {
      height: 2.5rem;
    }
  }
}
.manager-360-modal {
  .request-input {
    border-radius: 3px !important;
  }
  .add-new-input:hover {
    rect {
      fill-opacity: 1;
    }
  }
  .request-input-group {
    overflow: none;
    -ms-overflow-style: none;

    & .mb-075 {
      margin-bottom: 0.75rem !important;
    }

    @include gray-scrollbar;

    .input-group {
      overflow-y: scroll;
      max-height: 30rem !important;
    }
  }
  .is-invalid-input {
    border: 1px solid #b22222 !important;
  }

  .request-input-disabled {
    background-color: transparent !important;
  }

  .rightclick-icon {
    top: 0.6rem;
    z-index: 1;
    position: absolute !important;
  }

  .email-input-wrapper {
    border-radius: 3px !important;
    border: 1px solid darkgrey !important;
    & input:disabled {
      background-color: transparent !important;
    }
    & .typeform-taken {
      flex-grow: 2;
      border: none !important;
      &:focus {
        outline: none !important;
      }
    }
  }
}

.courses-score {
  font-family: 'Open Sans';
  font-size: 10.5pt;
}

.comparison-links {
  margin-top: 0.75rem;
  max-height: 10rem;
  overflow: overlay;
  overflow-x: hidden;
  @include gray-scrollbar;
  & .links {
    color: #000;
    margin-top: 0.75rem;
    &:hover {
      color: map-get($saffron, '700');
      text-decoration: none;
    }
  }
}

.action-cards {
  & {
    padding: 20px 21px 0px 22px;
    background: map-get($gray, '104');
    margin-bottom: 32px;
    border: 0;
    //max-height: 10rem;
    &:hover {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    & .card-title-text {
      margin-left: 12px;
    }
  }
  &.awareness-assessment-card {
    @include bottom-card-icons(#000, #000, #efc030);
  }
  &.leader-assessment-card {
    @include bottom-card-icons(#000, #efc030, #000);
  }
  &.simple-360-card {
    @include bottom-card-icons(#efc030, #000, #000);
  }
}
.new-assessment-link:hover {
  text-decoration: none;
}
.mr-20 {
  margin-right: 1.25rem;
}

.overflow-category-name {
  overflow-x: visible;
  white-space: nowrap;
}
.compare-date {
  & .ab-select__control {
    cursor: pointer;
  }
}

.h-13 {
  height: 13.25rem;
  max-height: unset !important;
}

@include media-breakpoint-up(lg) {
  .survey-header-text {
    font-family: 'Montserrat';
    line-height: 2.438rem;
    font-size: 2rem;
    letter-spacing: -0.02rem;
    margin-left: 1.178rem;
  }
  .goback-icon {
    width: 1.072rem;
    height: 2.081rem;
  }
  .px-lg-45 {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
}

@include media-breakpoint-down(lg) {
  .survey-header-text {
    font-family: 'Open Sans';
    line-height: 1.625rem;
    font-size: 1.125rem;
    letter-spacing: -0.04rem;
    margin-left: 1rem;
  }
  .goback-icon {
    width: 0.678rem;
    height: 1.316rem;
  }
}
@include media-breakpoint-down(md) {
  .px-sm-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .px-lg-15 {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }
  .email-input-wrapper {
    padding-right: 2rem !important;
  }
  .rightclick-icon {
    right: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rightclick-icon {
    right: 1rem;
  }
  .email-input-wrapper {
    padding-right: 2rem !important;
  }
}

@media (max-width: 767px) {
  .rightclick-icon {
    right: 0.6rem;
  }
  .email-input-wrapper {
    padding-right: 1.3rem !important;
  }
  .survey-page .survey-result .assessment-actions button:first-child {
    margin-right: 0rem;
  }
}
.action-cards-wrapper {
  & .individual-card {
    margin-bottom: 32px !important;
  }
}
.analytics-tabs {
  .nav-link.active {
    border-color: transparent !important;
  }
}
.analytics-bottom-section {
  width: 97%;
}
@include media-breakpoint-up(lg) {
  .action-cards-wrapper {
    & .first-card {
      padding-left: 0rem !important;
      padding-right: 1.5rem;
    }
    & .third-card {
      padding-left: 1.5rem;
      padding-right: 0rem !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .action-cards-wrapper {
    & .first-card {
      padding-left: 0rem !important;
      padding-right: 1.5rem;
    }
    & .second-card {
      padding-right: 0rem !important;
      padding-left: 1.5rem;
    }
    & .third-card {
      padding-left: 0rem !important;
      padding-right: 1.5rem;
    }
  }
}
@include media-breakpoint-down(sm) {
  .action-cards-wrapper {
    & .first-card,
    .second-card,
    .third-card {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
  }
}
