.learning-journey {
  border: 1px solid #000000;
  border-radius: 20px;
  & .section-wrapper {
    margin: 0rem 0.688rem;
  }
}

.journeys-section {
  & .section-title {
    margin-bottom: 1.25rem;
  }
}

.border-none {
  border: none;
}

.media-remove-icon {
  width: 0.75rem !important;
  height: 1.213rem !important;
  transform: rotate(180deg);
}

.add-medias {
  transform: rotate(0deg) !important;
}

.search-journey-input {
  border: 1px solid #000000;
  margin-top: 1.25rem;
}

.search-col-first {
  flex: 0 0 5%;
  max-width: 5%;
}

.search-col-second {
  flex: 0 0 95%;
  max-width: 95%;
}

.media-dropdown {
  border: 1px solid #000000;
  border-radius: 20px;
  padding: 0.688rem 0.938rem;
  & .ab-select__control {
    border-color: #000 !important;
  }
}

.journey-accordion {
  & .header {
    background: map-get($gray, '100');
    padding: 0.875rem;
  }
  & .wrapper-accordion {
    background: map-get($gray, '100');
  }
}

.journey-divider {
  border-left: 1px solid #000000;
}

.journeys-custom-col-first {
  flex: 0 0 49.5%;
  max-width: 49.5%;
}
.journeys-custom-col-second {
  flex: 0 0 1%;
  max-width: 1%;
}
.journeys-custom-col-third {
  flex: 0 0 49.5%;
  max-width: 49.5%;
}
