.simple-360 {
  & .answer360 {
    margin-top: 0.563rem;
  }
  & .simple-360-tabs {
    margin-bottom: 1.813rem;
  }
  & .tab-button-next {
    top: 176px;
  }
}

.mb-025 {
  margin-bottom: 0.25rem;
}
