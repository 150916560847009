$grid-columns: 12;
$grid-container-columns: 16;
$grid-gutter-width: 1.5rem;
@import '~@abroad/components/src/styles.module.scss';
@import './signuppreview.scss';
@import './common.scss';
@import './assessment-result.scss';
@media (min-height: 560px),
  (min-height: 610px) and (-webkit-device-pixel-ratio: 1.5) {
  .h-25 {
    height: 20% !important;
  }
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .custom-col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }
    }

    .custom-col#{$infix},
    .custom-col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .custom-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .custom-row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .custom-col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .custom-col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }

      .custom-order#{$infix}-first {
        order: -1;
      }

      .custom-order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .custom-order#{$infix}-#{$i} {
          order: $i;
        }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .custom-offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

@mixin make-grid-for-custom-container(
  $columns: $grid-container-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .container-col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }
    }

    .container-col#{$infix},
    .container-col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .container-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .container-row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .container-col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .container-col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }

      .container-order#{$infix}-first {
        order: -1;
      }

      .container-order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .container-order#{$infix}-#{$i} {
          order: $i;
        }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .container-offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

.body-content {
  @include make-grid-columns(14);
}

.custom-container {
  @include make-grid-for-custom-container(16);
}

.network-plan-setup {
  padding-top: 1rem;
  border-top: 1px $gray-600 dashed;
  border-bottom: 1px $gray-600 dashed;
  margin-bottom: 1rem;
}
@media print {
  .score-height {
    height: 22.4rem !important;
  }
}
