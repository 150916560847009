@media screen and (min-width: 1400px) and (max-width: 2359px) {
  .preview-modal {
    font-size: 18px;
  }
}

@media screen and (min-width: 2360px) and (max-width: 3300px) {
  .preview-modal {
    font-size: 20px;
  }
}

@media screen and (min-width: 3301px) and (max-width: 3839px) {
  .preview-modal {
    font-size: 22px;
  }
}

@media screen and (min-width: 3840px) {
  .preview-modal {
    font-size: 28px;
  }
}

$abroad-blue: #0a223c;
.preview-onboard {
  .form-group {
    margin-bottom: 2.5rem;
  }
}
.bg-abroad-blue {
  background-color: $abroad-blue;
}
.signup-left-section {
  margin-top: 9.5rem;
}
.signup-invite-link {
  margin-top: 6.5rem;
}
.onboard-wrapper {
  max-width: 21em;
  width: 100%;
}
.signup {
  .signup-form {
    width: 100%;
    height: auto;
    transition: 1s;
  }
}
.name-fields {
  .form-group {
    width: calc(50% - 10px);
  }
}
.preview-onboard {
  .form-label {
    color: rgba(255, 255, 255, 0.7);
  }
  .field-label {
    font-family: Montserrat;
    font-size: 0.625em;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2em;
    margin-bottom: 0.75rem;
  }
  .form-control {
    font-family: Open Sans !important;
    font-weight: 600 !important;
    font-size: 0.875em !important;
    line-height: 1.5rem !important;
    letter-spacing: -0.02em !important;
    border: 2px solid rgba(243, 246, 246, 0.7) !important;
    height: 2.5em !important; //calc(1.5em + 0.75rem)
    background-color: rgba(243, 246, 246, 0.1) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .signup-preview-s1 {
    font-family: 'Cormorant Garamond' !important;
    font-weight: 500 !important;
    font-size: 2.75em !important;
    line-height: 4.5rem !important;
    letter-spacing: -0.04em !important;
  }
  .signup-preview-s5 {
    font-family: 'Open Sans' !important;
    font-weight: 500 !important;
    line-height: 2.5rem !important;
    font-size: 1.125em !important;
    letter-spacing: -0.023em !important;
  }
  .signup-preview-s8 {
    font-family: 'Open Sans' !important;
    font-weight: 600 !important;
    font-size: 0.813em !important;
    line-height: 1.25em !important;
    letter-spacing: 0 !important;
  }
  .signup-preview-s9 {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    font-size: 0.75em !important;
    letter-spacing: 0.15em !important;
    line-height: normal !important;
    text-transform: uppercase !important;
  }
  .page-bottom-link {
    bottom: 2.5rem;
    @extend .signup-preview-s8;
  }
  .abroad-logo {
    margin-top: 3rem;
  }
  .onbording-action-btn {
    margin-top: 3.25rem;
  }
  .btn-saffron {
    line-height: normal !important;
    @extend .signup-preview-s9;
    background-color: map-get($saffron, '300');
    color: $black;
    text-align: center;
    border-radius: 100px !important;
    padding: 1rem 0;
    opacity: 0.5;
    // height: 3.75rem;
    &:disabled {
      background-color: map-get($saffron, '300') !important;
      color: $black !important;
      opacity: 0.5;
    }
  }
  .btn-custom {
    line-height: normal !important;
    @extend .s9;
    text-align: center;
    border-radius: 100px;
    padding: 1rem 0;
  }
  .border-radius-100 {
    border-radius: 100px !important;
  }
}
.mt-32px {
  margin-top: 2rem;
}
.border-radius-5 {
  border-radius: 0.313rem;
}

@include media-breakpoint-down(lg) {
  .page-bottom-link {
    position: relative !important;
    bottom: 0 !important;
  }
}
@media (max-height: 600px) {
  .page-bottom-link {
    position: relative !important;
    bottom: 0 !important;
  }
}
