/* add css module styles here (optional) */
@use 'sass:map';
$enable-responsive-font-sizes: true;
$enable-validation-icons: false;
$form-feedback-valid-color: #509f92;
$form-feedback-invalid-color: #b22222;
$modal-backdrop-opacity: 0.25;
$input-border-color: #000000;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px,
  xxl: 1400px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == '%' or unit($prev-num) == '%' {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}
@include _assert-ascending($container-max-widths, '$container-max-widths');
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import 'styles/variables.scss';
@import 'styles/button.scss';
@import 'styles/font.scss';
@import 'styles/functions.scss';
@import 'styles/mixin.scss';
@import 'styles/sidebar.scss';
@import 'styles/survey.scss';
@import 'styles/tooltip.scss';
@import 'styles/mediaPlayer.scss';
@import 'styles/animation.scss';
@import 'styles/pages/onbording.scss';
@import 'styles/tagInput.scss';
@import 'styles/pages/payment.scss';
@import 'styles/pages/account.scss';
@import 'styles/pages/coach.scss';
@import 'styles/pages/organization.scss';
@import 'styles/common.scss';
@import 'styles/pages/clientProfile.scss';
@import 'styles/pages/growthPlan.scss';
@import 'styles/pages/simple360.scss';
@import 'styles/pages/journeys.scss';
@import 'styles/right-sidebar.scss';

@import '../../../node_modules/swiper/swiper.scss';
@import '../../../node_modules/swiper/components/navigation/navigation.scss';
@import '../../../node_modules/swiper/components/scrollbar/scrollbar.scss';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', 'Open Sans';
  color: $black;
}

@each $prop, $abbrev in (height: h) {
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $sizes {
        .#{$abbrev}-#{$breakpoint}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
  @each $size, $length in $customsize {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}

@each $prop, $abbrev in (x: x) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-left: $length;
        // display: inline;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-left: $length !important;
          }
        }
      }
    }
  }
}

@each $prop, $abbrev in (y: y) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-top: $length;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-top: $length;
          }
        }
      }
    }
  }
}

@each $name, $color in $colors {
  @each $prop, $opacity in $opacities {
    .bg-#{$name} {
      &.bg-opacity-#{$prop} {
        @include background-opacity($color, $opacity);
      }
    }
    .hover\:bg-#{$name} {
      &:hover {
        @include transition-all;
        &.hover\:bg-opacity-#{$prop} {
          @include background-opacity($color, $opacity);
        }
      }
    }
  }
}

@each $prop, $opacity in $opacities {
  .opacity-#{$prop} {
    opacity: $opacity;
  }
}

@each $prop, $abbrev in (width: w) {
  @each $size, $length in $custom-widths {
    $list: split-str($size, '/');
    .#{$abbrev}-#{nth($list, 1)}\/#{nth($list, 2)} {
      #{$prop}: $length;
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $custom-widths {
        $list: split-str($size, '/');
        .#{$abbrev}-#{$breakpoint}-#{nth($list, 1)}\/#{nth($list, 2)} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

@each $prop, $abbrev in (font-size: font) {
  @each $size, $length in $font-sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $font-sizes {
        .#{$abbrev}-#{$breakpoint}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .vh-lg-100 {
    height: 100vh !important;
  }
  .text-lg-error {
    color: map-get($red, '600') !important;
  }

  .text-lg-emerald {
    color: $emerald !important;
  }
  .decoration-lg-none {
    text-decoration: none !important;
  }
  .left-lg-10 {
    left: 10.2rem;
  }
  .left-lg-12 {
    left: 12rem;
  }
  .media-card-title {
    line-height: 1.5rem;
    letter-spacing: -0.02rem;
    margin-top: 0.788rem;
  }
  .media-card-minutes {
    letter-spacing: 0rem;
    line-height: 1.25rem;
    margin-top: 0.25rem;
  }
  .mr-lg-99px {
    margin-right: 99px;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
  .mt-lg-60px {
    margin-top: 3.75rem !important;
  }
  .mt-lg-50px {
    margin-top: 3.25rem !important;
  }
  .mb-lg-60px {
    margin-bottom: 3.75rem !important;
  }
  .padding-lg-journey-infocard {
    padding: 1.313rem 2.375rem;
  }
}

.line-through {
  text-decoration: line-through;
}

.text-error {
  color: map-get($red, '600') !important;
}

.text-emerald {
  color: $emerald !important;
}

.font-open-sans {
  font-family: 'Open Sans';
}

.font-montserrat {
  font-family: 'Montserrat' !important;
}

.font-weight-medium {
  font-weight: 500;
}

.fit-image {
  object-fit: contain;
}

.z2 {
  z-index: 2;
}

.z1048 {
  z-index: 1048;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z20 {
  z-index: 20;
}

.z99 {
  z-index: 99;
}

.mx-12 {
  margin: 0 0.75rem;
}

.pointer-event-none {
  pointer-events: none;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.icon-size {
  font-size: 3.2rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: white;
  border-width: 1px;
  border-radius: 3px;
}
input[type='checkbox'] {
  height: 0.75rem;
  width: 0.75rem;
  top: 0.35rem;
  + label {
    &::after,
    &::before {
      height: 0.75rem;
      width: 0.75rem;
      cursor: pointer;
    }
    &:hover {
      &::before {
        border-color: rgb(0, 0, 0) !important;
      }
    }
    &::after {
      top: 0.35rem;
    }
    &::before {
      border-color: rgba(0, 0, 0, 0.7);
      top: 0.35rem;
      border-radius: 3px;
    }
  }
}

.table {
  tr {
    td {
      input[type='checkbox'] {
        top: 0.12rem;
        + label {
          &::after {
            top: 0.12rem;
          }
          &::before {
            top: 0.12rem;
          }
        }
      }
    }
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  // background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='rgb(57, 199, 75)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
  background-image: none !important;
  background-color: #509f66 !important;
  border: 1px solid #509f66 !important;
  background-image: none;
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #509f66 !important;
}

.shadow {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow {
  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2) !important;
  }
}

.session-card-shadow {
  @include transition;
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-7 {
  border-radius: 7px;
}

.rounded-left-10 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rounded-left-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-right-5 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-right-10 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.form-field-validation-icon {
  top: 2px;
  right: -30px;
}

.is-form-field-valid {
  background-color: rgba(57, 199, 75, 0.15);
  border-color: rgba(57, 199, 75, 1);
}

.is-form-field-invalid {
  background-color: rgba(199, 57, 57, 0.15);
  border-color: rgba(199, 57, 57, 1);
}

.form-control::-webkit-input-placeholder {
  color: #828282;
}
.form-control:-moz-placeholder {
  color: #828282;
}
.form-control::-moz-placeholder {
  color: #828282;
}
.form-control:-ms-input-placeholder {
  color: #828282;
}
.form-control::-ms-input-placeholder {
  color: #828282;
}

.breakthrough-results {
  margin-top: 150px;
  .table {
    font-family: 'Open Sans';
    thead {
      th {
        font-weight: 600;
        border-top: 0px !important;
        border-bottom: map-get($saffron, '700') solid 3px !important;
        &:last-child {
          background-color: #eeeeee;
          border-radius: 12px 12px 0px 0px;
        }
      }
    }
    td {
      border-top: 0px !important;
      border-bottom: #eeeeee solid 3px !important;
      &:last-child {
        background-color: #eeeeee;
      }
    }
    tr {
      font-weight: 400;
      &:nth-child(2),
      &:nth-child(7) {
        font-weight: 600;
        color: map-get($saffron, '700');
      }
      &:nth-last-child(2) {
        td {
          &:last-child {
            border-bottom: 0px !important;
            border-radius: 0px 0px 12px 12px;
          }
        }
      }
      &:last-child {
        td {
          background: none !important;
          &:last-child {
            padding: 0.35rem;
            border-bottom: 0px !important;
          }
        }
      }
    }
  }
}

.tracking-tighter {
  letter-spacing: -0.05em;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-tight-2 {
  letter-spacing: -0.02em;
}
.tracking-normal {
  letter-spacing: 0;
}
.tracking-xs-wide {
  letter-spacing: 0.025em;
}
.tracking-sm-wide {
  letter-spacing: 0.05em;
}
.tracking-md-wide {
  letter-spacing: 0.1em;
}
.tracking-lg-wide {
  letter-spacing: 0.2em;
}
.tracking-lg-wide-point-25 {
  letter-spacing: 0.25em !important;
}
.tracking-xl-wide {
  letter-spacing: 0.3em;
}
.tracking-2xl-wide {
  letter-spacing: 0.4em;
}

.card-avatar {
  border: 3px solid #fafafa;
  border-radius: 100%;
  width: 96px;
  height: 96px;
  &.position-n {
    margin: -48px;
  }
}

.testimonial-body {
  height: auto;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  .avatar-title {
    line-height: 15px;
  }
}

.pointer-cursor {
  @include pointer-cursor;
}

.login-error {
  border-radius: 0;
  background-color: rgba(map-get($red, '600'), 0.5);
}

.underline {
  text-decoration: underline;
}

.leading-3 {
  line-height: 0.75rem !important;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-7-half {
  line-height: 1.875rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-none {
  line-height: 1rem;
}
.leading-tight {
  line-height: 1.25rem;
}
.leading-snug {
  line-height: 1.375rem;
}
.leading-normal {
  line-height: 1.5rem;
}
.leading-relaxed {
  line-height: 1.625rem;
}
.leading-loose {
  line-height: 2rem;
}

.verify-email-banner-img {
  margin-left: -83%;
}

input:disabled {
  background-color: map-get($gray, '400') !important;
}

.password-visibility-icon,
.info-visibility-icon,
.abtext-close-icon {
  top: 0.4rem;
  right: 10px;
  z-index: 1;
  cursor: pointer;
}

.password-visibility-input {
  padding-right: 10%;
}

.tooltip-box {
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.29) !important;
  border-radius: 10px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.media-player-img {
  width: 100%;
}

.breakthrough {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  left: 0;
}

.breakthrough-icon {
  font-size: 2.5rem;
}

@include media-breakpoint-up(lg) {
  .breakthrough-container {
    height: 100vh;
  }
  .breakthrough-media {
    aspect-ratio: 5;
    .upgrade-remainder {
      @include transition-all;
      display: none;
    }
    &:hover {
      .upgrade-remainder {
        display: flex;
      }
      .bt-title-card {
        display: none !important;
      }
    }
  }
  .breakthrough-media-card {
    @include transition-all;
    .play-icon {
      display: none;
    }
    .update-text {
      display: none;
    }
    &:hover {
      .update-text {
        display: block;
      }
      .card {
        display: none;
      }
      .play-icon {
        display: block;
      }
    }
  }
  .layout-with-play-bar {
    padding-bottom: 6rem;
    transition: padding-bottom 500ms ease-out;
  }
  .notification-container {
    position: fixed;
    top: 1rem;
    right: 1rem;
    max-height: 100vh;
    height: 'auto';
    overflow: auto;
    width: 300px;
    z-index: 1100;
  }
  .banner-image-my {
    margin-top: 40px;
    margin-bottom: 28px;
  }
}

@include media-breakpoint-down(sm) {
  .merge-dropdown-section {
    padding-bottom: 0px !important;
    .search-media-list {
      width: calc((100% / 12) * 11) !important;
    }
  }
  .media-player-img {
    width: auto !important;
    margin-left: -100%;
  }
  .session-modal-body {
    overflow-y: scroll;
    max-height: 100vh;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}

@include media-breakpoint-down(lg) {
  .password-visibility-icon {
    top: 0.6rem !important;
  }
  .info-visibility-icon {
    top: 2.5rem !important;
  }
  .breakthrough-media {
    aspect-ratio: 5/2;
    .upgrade-remainder {
      @include transition-all;
      display: none;
    }
    &:hover {
      .upgrade-remainder {
        display: flex;
      }
      .bt-title-card {
        display: none;
      }
    }
  }
  .breakthrough-media-card {
    @include transition-all;
    .update-text {
      display: none;
    }
    .play-icon {
      display: none;
    }
    &:hover {
      .update-text {
        display: block;
      }
      .card {
        display: none;
      }
      .play-icon {
        display: block;
      }
    }
  }

  .breakthrough-container {
    height: calc(100vh - 45px);
  }
  .breakthrough {
    bottom: 6.5rem !important;
  }
  .breakthrough-icon {
    font-size: 2rem;
  }
  .upgrade-remainder {
    display: flex;
    @include background-opacity($black, 0.75);
  }
  .play-icon {
    display: none;
  }
  .notification-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    overflow: auto;
    z-index: 1100;
  }
  .intention-textarea {
    border-bottom: 2px solid #000 !important;
    border-radius: 0 !important;
    + .characters-limit {
      @include transition-all;
      visibility: visible !important;
      opacity: 1 !important;
      height: auto !important;
    }
  }
  .view-assessment-btn {
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
  }
  .media-card-title {
    line-height: 1rem;
    letter-spacing: -0.04rem;
    margin-top: 0.421rem;
  }
  .media-card-minutes {
    letter-spacing: -0.02rem;
    line-height: 1rem;
    margin-top: 0.125rem;
  }
  .banner-image-my {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

// SVG
.download-icon {
  width: fit-content;
  @include transition-all;
  svg {
    fill: none;
    path {
      fill: $white;
    }
  }
  &:hover {
    svg {
      fill: $white;
      path {
        fill: map-get($saffron, '700');
      }
    }
  }
}

.backword-icon-svg:hover,
.forward-icon-svg:hover {
  text,
  path {
    fill: map-get($saffron, '300');
  }
}

.growth-intention {
  @include swiper-buttons(65%);
}

@include swiper-buttons(53%);

@include media-breakpoint-up(lg) {
  .play-bar-image {
    width: 64px;
    height: 64px;
    img {
      border-radius: 5px;
    }
    & .img-link {
      display: none;
    }
    &:hover {
      & .img-link {
        display: flex;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .play-bar-image {
    width: 48px;
    height: 48px;
    img {
      border-radius: 5px;
    }
  }
}

.playbar-title {
  width: calc(100% - 96px);
  a {
    &:first-child {
      line-height: 1.75rem;
    }
  }
}

.truncate-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.accordion-icon {
  svg {
    circle {
      fill: #646464;
      @include transition(fill 0.25s ease-in-out);
    }
    &:hover {
      circle {
        fill: #000000;
      }
    }
  }
}

.divider {
  border-color: #a0a0a0;
  border-width: 3px;
}

.intentions {
  .sider-icon {
    position: absolute;
    top: 10rem;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.notification-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 99;
}

.insight-textarea {
  &::-webkit-scrollbar {
    display: none;
  }
  &.form-control {
    height: 3rem;
  }
  border: 0px;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: #cbcbcb;
    border-radius: 0;
  }
  &:focus,
  &:active {
    border-color: #cbcbcb;
    border-radius: 0;
    + .characters-limit {
      @include transition-all;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
  + .characters-limit {
    @include transition-all;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.intention-textarea {
  &::-webkit-scrollbar {
    display: none;
  }
  border: 0px;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.75rem;
  margin-bottom: 0.25rem;
  letter-spacing: -0.04rem;
  border-color: #000;
  border-radius: 0;
  &:focus,
  &:active {
    border-color: #000;
    border-radius: 0;
    + .characters-limit {
      @include transition-all;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
  + .characters-limit {
    @include transition-all;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.hasIntention,
.insight-error {
  border-color: #c73939 !important;
  border-radius: 0;
}

.archived-intention {
  border-bottom: 4px solid #000000;
}

.profile-dropdown {
  transform: translate(0px, 11px) !important;
  .dropdown-item {
    font-family: 'Open Sans';
    font-size: 1rem;
    line-height: 1.75rem;
    letter-spacing: -0.02rem;
    font-weight: 600;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #000 !important;
    font-weight: 700;
    background-color: transparent !important;
  }
  .dropdown-item:hover {
    font-weight: 700;
    background-color: transparent !important;
    color: #000;
  }
}

.help-dropdown {
  padding: 1.188rem 1.438rem;
}

.profile {
  label {
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: right;
    font-weight: 600;
    font-size: 0.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .form-control {
    border: 1px solid #000 !important;
    background: #fff;
    font-size: 12px;
    border-radius: 5px;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: #b22222 !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: #509f92 !important;
    }
  }
  select:disabled {
    background-color: #c2c2c2 !important;
  }
}

@include media-breakpoint-up(lg) {
  .intention-textarea {
    &.form-control {
      height: 3rem;
    }
  }
  .help-dropdown {
    width: 400px;
  }
  .right-sidebar-content {
    width: 20%;
  }
}

@include media-breakpoint-up(md) {
  .pr-md-80 {
    padding-right: 80px;
  }
  .pl-md-80 {
    padding-left: 80px;
  }
}

@include media-breakpoint-down(lg) {
  .intention-textarea {
    &.form-control {
      height: 4.5rem;
    }
  }
  .profile-dropdown {
    .dropdown-item {
      color: $black !important;
    }
  }
  .right-sidebar-content {
    width: 80%;
  }
}

.assessment-table {
  .table-responsive {
    td {
      vertical-align: middle;
    }
    .form-control {
      border: 1px solid darkgrey !important;
      background: #fafafa;
    }
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: map-get($saffron, '300') !important;
}

.daterangepicker td.in-range {
  background-color: rgba(239, 192, 48, 0.2);
}

.pause-icon-white {
  line {
    stroke: $black;
  }
  &:hover {
    line {
      stroke: map-get($saffron, '700');
    }
  }
}
.play-icon-white {
  path {
    fill: $black;
  }
  &:hover {
    path {
      fill: map-get($saffron, '700');
    }
  }
}

.fluid-img {
  max-width: 100%;
  height: 100%;
}

.not-found {
  .home-link {
    position: relative;
    top: 8.5rem;
  }
  .w-lg-35 {
    width: 35%;
  }
}

@include media-breakpoint-down(sm) {
  .not-found {
    .w-sm-100 {
      width: 100% !important;
    }
    .home-link {
      position: absolute;
      top: unset;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  .text-left-sm {
    text-align: left !important;
  }
  .max-width-sm-250 {
    max-width: 250px !important;
  }
  .max-width-sm-200 {
    max-width: 200px !important;
  }
}

.bg-green-with-opacity {
  background-color: rgba(80, 159, 146, 0.3);
}

//Upgrade Plan css
.upgrade-text:hover {
  text-decoration: underline !important;
}
.disabled-link {
  pointer-events: none !important;
}
.media-title {
  a,
  span,
  .link,
  .title {
    color: #000;
  }
  .title {
    display: flex;
  }
  .link {
    display: none;
  }
  .text-d-none {
    display: block;
  }
  &:hover,
  &:focus,
  &:active {
    .title {
      display: none !important;
    }
    .link {
      display: flex;
    }
    .text-d-none {
      display: none;
    }
  }
}
.media-time {
  text-align: right;
}
.row {
  .p-hover-0 {
    padding: 0px !important;
  }
}

.intention {
  .upgrage-account-text {
    display: none;
  }
  .upgrade-text {
    display: none;
  }
  .upgrade-plan-box {
    opacity: 0;
    visibility: hidden;
    @include transition-all;
  }
  &:hover {
    .upgrade-plan-box {
      opacity: 1;
      visibility: visible;
      background: rgba(0, 0, 0, 0.75) !important;
      @include transition-all;
    }
    .upgrade-text {
      display: block;
    }
    .intention-box {
      opacity: 0;
    }
    .upgrage-account-text {
      display: flex;
    }
  }
}
.breakthrough-checkmark {
  margin-bottom: 0.2rem;
}

.bt-container {
  height: calc(100% - 6rem);
}

.bt-module {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: 4px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #f2f5f5;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}
.media-info-container {
  max-width: 440px;
}
.media-action {
  max-width: 440px;
  margin: 0 auto;
}

.module-content {
  margin: 0 auto;
  .icons {
    margin-bottom: 28px;
  }
}
.module-details {
  background-color: #f3f5f5;
  margin: 0 auto;
  width: 100%;
  padding: 20px 25px 16px 25px;
  outline: $black solid 1.5px;
  .auther {
    margin-bottom: 16px;
  }
}

@include media-breakpoint-up(lg) {
  .module-container {
    .media-content {
      padding: 0px 25px 0px 25px;
    }
    .extra-audio {
      background-color: #f3f5f5;
      outline: $black solid 1.5px;
      padding-top: 11px;
      padding-bottom: 15px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .module-container {
    .extra-audio {
      background-color: #f3f5f5;
      outline: $black solid 1.5px;
      padding-top: 11px;
      padding-bottom: 15px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .media-info-container {
    margin-top: calc(100vh * 10 / 100);
  }
  .media-info-container,
  .media-action {
    max-width: 490px;
    margin: 0 auto;
  }
  .programs-wrapper {
    max-width: calc(680px - 32px);
    margin: 0 auto;
  }
  .module-content {
    max-width: 648px;
  }
  .skills-content {
    padding: 0.6rem 1.563rem 0.6rem 1.563rem;
  }
}

@media (min-width: 1400px) {
  .media-info-container {
    margin-top: calc(100vh * 10 / 100);
  }
  .media-info-container,
  .media-action {
    max-width: 540px;
    margin: 0 auto;
  }
  .programs-wrapper {
    max-width: calc(803px - 40px);
    margin: 0 auto;
  }
  .module-content {
    max-width: 763px;
  }
  .skills-content {
    padding: 0.6rem 1.563rem 0.6rem 1.563rem;
  }
}

.back-btn {
  z-index: 2;
  svg {
    &:first-child {
      display: inline;
    }
    &:last-child {
      display: none;
    }
  }
  &:hover {
    svg {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: inline;
      }
    }
  }
}
.media-info-container {
  margin-top: calc(100vh * 5 / 100);
}

.media-info {
  .type {
    color: $white;
    margin-bottom: 6px;
  }
  .title {
    color: $white;
  }
  .description {
    color: #ffffff;
    margin-bottom: 12px;
  }
  .description-section {
    margin-top: 17px;
  }
}
.module-container {
  margin-top: 40px;
  min-height: 4.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  .module-list {
    > :nth-child(n + 2) {
      margin-top: 40px;
    }
    .module-media-container {
      display: flex;
      flex-wrap: wrap;
      .tick-icon {
        margin-right: 8px;
      }
      &:hover {
        .media-title {
          a,
          span,
          .link,
          .title {
            font-weight: 600 !important;
          }
        }
        .media-time {
          font-weight: 700 !important;
        }
      }
    }
  }
}
.media-action-content {
  > :nth-child(n + 2) {
    margin-left: 4rem;
  }
}

.img-cover {
  object-fit: cover;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-disabled {
  cursor: not-allowed;
}
a.cursor-disabled {
  text-decoration: none;
}
.btn.cursor-disabled:hover {
  color: #fff !important;
  background-color: #343a40 !important;
}
// cookie popup
.react-cookie-law-dialog {
  bottom: 0 !important;
  top: unset !important;
  background-color: #000 !important;
}

.react-cookie-law-manage-btn,
.react-cookie-law-save-btn,
.react-cookie-law-accept-btn {
  font-family: 'Montserrat' !important;
  border: none !important;
  border-radius: 5px !important;
  margin: 5px !important;
  padding: 5px 15px !important;
  text-transform: capitalize !important;
}

.react-cookie-law-manage-btn {
  color: #efc030 !important;
  outline: none !important;
  &:hover {
    color: #d5a000 !important;
  }
}

.react-cookie-law-accept-btn,
.react-cookie-law-save-btn {
  color: '#fff' !important;
  font-weight: 500;
  background-color: #efc030 !important;
  outline: none !important;
  &:hover {
    background-color: #d5a000 !important;
  }
}

.pointer-event-auto {
  pointer-events: auto;
}

.intention-info {
  border-bottom: 3px solid #bf9000;
}

.gp-label {
  letter-spacing: 6px;
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.7) !important;
  }
}

.archive-border-right {
  border-right: 1px solid black;
}

.delete-intention-box .modal-content {
  box-shadow: 20px 20px 10px #00000029;
}

.logout-button:focus {
  outline: 0 !important;
}

.swiper-button-disabled {
  display: none !important;
}

#abroad-community {
  width: 100%;
  border: none;
  min-height: 100%;
  box-shadow: none;
}

.image-delete {
  cursor: pointer;
  position: absolute;
  top: 20px;
  margin-left: -40px;
  border-radius: 25px;
  background: white;
  padding: 5px 10px;
}

.audio-delete {
  cursor: pointer;
  position: absolute;
  top: 15px;
  margin-left: 10px;
}

.transcript-delete {
  margin-left: 10px;
}

.under-line {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
}

[data-tooltip]:hover:before {
  opacity: 1;
}

.card-counter {
  border-radius: 0.3rem !important;
  border: 1px solid darkgrey !important;
}

.f-icon {
  margin-top: -35px !important;
  border: 1px solid #fff !important;
  width: 80px !important;
  height: 80px !important;
}

.circle-profile {
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
}
.border-radius-0 {
  border-radius: 0;
}

.txt-hover-underline {
  text-decoration: underline;
  text-decoration-color: white;
}

.create-cohort-btn {
  color: #fff;
  background: #000000;
  border: 2px solid #333333;
  border-radius: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500 !important;
  &:hover {
    background: #fff;
    color: #333333;
    text-decoration: none;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}
.text-center {
  text-align: center;
}
.pay-sucess-txt {
  color: #509f92;
}

.underline-link {
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
}

.outline-none {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.sitecopy {
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.site-copy-btn-active {
  text-decoration: none !important;
  background-color: #000;
  color: #fff !important;
  font-weight: 500 !important;
  &:hover {
    background-color: #428bca !important;
  }
}
.site-copy-btn {
  text-decoration: none !important;
  &:hover {
    background-color: #dfd4d4 !important;
    color: #333 !important;
    border-radius: 10px !important;
  }
}
.subcategory-questions {
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0 !important;
}

.site-copy-sub-category .form-control {
  border: 1px solid darkgrey !important;
  background: #fafafa;
  font-size: 12pt;
}

.search-user-list {
  @include search-input(97.5%, 165px);
  overflow-x: hidden;
  li {
    padding: 3px;
    &:hover {
      background-color: #428bca !important;
      cursor: pointer;
    }
  }
}

.margin-minus-17 {
  margin-top: -17px !important;
}
.z-100 {
  z-index: 100;
}

@include media-breakpoint-up(lg) {
  .mt-lg-13px {
    margin-top: -13px;
  }
  .px-lg-80 {
    padding: 0 80px;
  }
}
.dropdown-section {
  padding-bottom: 165px;
  .search-media-list {
    width: calc((100% / 12) * 11) !important;
  }
}
.merge-dropdown-section {
  padding-bottom: 50px;
  .search-media-list {
    width: calc((100% / 12) * 11) !important;
  }
}
.search-media-list {
  @include search-input(89.8%, 165px);
  overflow-y: scroll;
  li {
    padding: 3px;
    &:hover {
      background-color: #428bca !important;
      cursor: pointer;
    }
  }
}

.custom-selection {
  input {
    &:disabled {
      width: 0px !important;
    }
  }
}
.user-profile-btn {
  text-transform: uppercase !important;
  border: 2px solid #333333 !important;
  border-radius: 20px;
  font-weight: 500 !important;
  &:hover {
    background-color: #fff !important;
    color: #000000 !important;
  }
}

.btn-report {
  font-size: x-small !important;
  background-color: #000 !important;
  font-weight: 500 !important;
  height: 25px !important;
  &:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
}
.add-companies-btn {
  position: absolute;
  right: 5px;
}
.generate-dropdown {
  height: 100%;
  width: 200px;
  margin-right: 5px;
}
.text-disabled {
  color: #d3cdcc;
}
.client-no-coaching .form-group {
  margin-bottom: 5px;
}
.client-no-coaching label {
  text-align: left;
}
.create-promo .form-control {
  border: 1px solid darkgrey !important;
  background: #fafafa;
  font-size: 12pt;
}
.promo-inactive {
  color: red;
}
.promo-active {
  color: green;
}
.match-promo {
  background-color: #9bdfb2 !important;
}

.merge-companies .form-control {
  border: 1px solid darkgrey !important;
  background: #fafafa;
  font-size: 12pt;
}

.sm-btn {
  font-size: 10px !important;
  background-color: #000 !important;
  letter-spacing: 1.5px;
  font-weight: 500 !important;
  &[disabled] {
    background-color: #595959 !important;
    &:hover {
      background-color: #595959 !important;
      color: #fff !important;
      pointer-events: none !important;
    }
  }
  &:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.client-profile-datepicker input {
  padding: 8px;
}
.header-fixed-bottom {
  bottom: 0px !important;
  position: absolute !important;
}
.network-select {
  width: 50%;
}
.word-breakall {
  word-break: break-all;
}
.insight-height-48px {
  height: 48px !important;
}
.insight-height-84px {
  height: 84px !important;
}
.insight-height-120px {
  height: 120px !important;
}
#sidebar .down-separator {
  border-bottom: 3px solid #282828 !important;
}
#sidebar .sidebar-link-separator {
  border-bottom: 3px solid #1e1e1e !important;
}
.payment-table {
  position: sticky !important;
  top: 0 !important;
  z-index: 20 !important;
  width: 100% !important;
  background-color: #fdfdfd !important;
}
.disabled-input {
  background-color: #c2c2c2 !important;
}
input[type='checkbox']:disabled:checked + label::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='rgb(255,255,255)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !important;
  background-color: rgb(35, 125, 250) !important;
}
.children-container {
  min-height: calc(100% - 52px) !important;
}
.team-listing-tbl {
  table-layout: fixed;
}
.word-break {
  word-break: break-word;
}
.bg-white-shadow {
  background-color: #fafafa;
  border-color: #a6a6a6 !important;
  border: 1px solid #ccc !important;
}
.two-fa-otp-input {
  justify-content: space-between;
}
.two-fa-otp-input input {
  width: 3rem !important;
  height: 3rem !important;
  border: 1px solid $black;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  margin-right: 1rem;
  &:focus-visible {
    border-radius: 10px;
    border: 1px solid $black;
    box-shadow: 0 0 0 1px $black;
  }
  &:active {
    box-shadow: 0 0 0 1px $black;
  }
  &:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .two-fa-otp-input input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: 8px;
  }
}
@media (max-width: 425px) {
  .two-fa-otp-input input {
    width: 2rem !important;
    height: 2rem !important;
  }
}
.two-fa-otp-error {
  border: 1px solid #b22222 !important;
}
.sdp-wrp,
.sdp-wrp .SingleDatePicker,
.sdp-wrp .SingleDatePickerInput,
.sdp-wrp .DateInput {
  width: 100%;
}
.calendar_controls {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  line-height: 12px;
  vertical-align: middle;
}
.calendar_controls .btn {
  margin-left: 8px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}
.calendar_controls .CalendarDay__default {
  border: none;
}
.sdp-wrp-err .SingleDatePickerInput {
  border: 1px solid #b22222 !important;
}
.sdp-wrp-err .DateInput_input,
.sdp-wrp .DateInput_input__focused {
  border-bottom: none;
}
.sdp-completed .SingleDatePickerInput {
  border: 1px solid #509f92 !important;
}

.DateInput_input {
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 1rem !important;
  background: #fafafa !important;
  font-size: 12pt !important;
  line-height: 1.5 !important;
  border: none !important;
  border-radius: 0px !important;
  font-weight: 400 !important;
}
.SingleDatePickerInput__withBorder {
  border: 1px solid darkgray !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}
.coaching-cycle {
  min-width: 250px;
}
.include-promocode {
  padding-top: 2px !important;
}
.w-60 {
  width: 60% !important;
}
.check-icon-bg {
  border-top-right-radius: 5px;
}
.bg-add-user {
  background-color: #eeeeee !important;
}
.archived-team-view {
  display: table-cell;
  vertical-align: middle;
}
.coaching-border-right {
  border-right: 1px solid $black;
}
.coaching-border-left {
  border-left: 1px solid $black;
}
.bg-coaching-date {
  background: #f2f5f5;
}
.bg-coaching-date-hover-shadow:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
}
.px-40 {
  padding: 0 40px;
}
.add-client-team-session-btn {
  background: none;
  border: none;
}
.coaching-modal-header-border {
  border-bottom: 1px solid $black;
}
.coaching-modal-audios-container {
  max-height: 30vh !important;
  overflow-y: scroll;
}
.coaching-modal-body {
  max-height: 70vh !important;
  overflow-y: scroll;
}
.session-modal-divider {
  border-color: $black;
  border-width: 1px;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}
.border-black {
  border-color: $black;
  border: 1px solid;
}
.media-image {
  color: $black;
  position: relative;
  .play-icon {
    display: none;
    visibility: hidden;
  }
  .main-image {
    position: relative;
  }
  &:hover,
  &:focus,
  &:active {
    .play-icon {
      position: absolute;
      display: block;
      visibility: visible;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}

.report-btn {
  background-color: #282828 !important;
  color: #fff !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #fff !important;
    color: #000 !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0% !important;
  }
  & .report-active-link {
    background-color: #fff !important;
    color: #000 !important;
  }
}
.angle-left-icon {
  transition: transform 0.35s linear;
}
.angle-down-icon {
  transform: rotate(90deg);
  transition: transform 0.35s linear;
}

.show-dropdown-items {
  animation: growDown 300ms ease-in-out forwards !important;
  transform-origin: top center !important;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
}

.module-info-divider {
  border: 2px solid #ffffff;
}
.audio-list-divider {
  width: 100%;
  display: flex;
  align-items: center;
  & .bar-progress {
    flex: 1;
    height: 2px;
    display: flex;
    align-items: center;
    background-color: rgba(80, 80, 80, 0.5);
    // background-color: #6f6f6f;
  }
}
.download-icon-rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.icon-xl-wide {
  letter-spacing: 0.3rem;
}
@media (max-width: 767px) {
  .icon-xl-wide {
    letter-spacing: 0rem;
  }
  .breakthrough-card {
    width: 100% !important;
  }
}
.pl-8px {
  padding-left: 8px;
}

.single-datepicker {
  border: 1px solid #000;
  background: '#fafafa';
  height: '42px';
}

.breakthrough-card {
  & .card {
    border-radius: 10px !important;
    border: 0px !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
  .sub-title {
    text-align: center;
    vertical-align: middle;
  }
  .title {
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .breakthrough-card {
    .card {
      width: 450px;
    }
  }
}

@include media-breakpoint-down(md) {
  .breakthrough-card {
    .sub-title {
      margin: 4px 0px 6px 0px;
    }
  }
  .abroad-sm-icon {
    width: 48px;
    height: 24px;
  }
}

.font-6 {
  font-size: 0.375rem;
}

.image-overlay {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 321px) {
  .max-width-sm-200 {
    max-width: 145px !important;
  }
}

.report-loader {
  width: 0.7rem !important;
  height: 0.7rem !important;
}
.privileges-checkbox {
  position: relative !important;
  width: auto !important;
  height: auto !important;
}
.code-icon {
  @include input-icon;
}
.eye-icon {
  @include input-icon;
}
.btn-width-10 {
  width: 10rem;
}
.mt-40 {
  margin-top: 2.5rem !important;
}
.group-main-input {
  height: 2rem;
}
.middle-audio-bar {
  margin-top: 7.5rem;
}
.middle-control-bar-icons {
  margin-top: 2.5rem;
}
.media-category-title {
  margin-bottom: 0.75rem;
}
.purpose-vision-form {
  .form-group {
    margin-bottom: 0 !important;
  }
  .form-control {
    border: 1px solid black !important;
    box-sizing: border-box;
    padding-left: 0.563rem;
    &::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: -0.02em;
      margin-left: 0.563rem;
      padding-left: 0;
      color: rgba(0, 0, 0, 0.5);
    }
    .was-validated &:invalid,
    &.is-invalid {
      border-color: #b22222 !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: #509f92 !important;
    }
  }
}
.mb-pv-form {
  margin-bottom: 2.125rem;
}

.vision-section {
  border-bottom: 2px solid #e7ecec;
}
.line-height-39 {
  line-height: 2.438rem;
}
.mb-point-75 {
  margin-bottom: 0.75rem;
}
.mt-point-75 {
  margin-top: 0.75rem;
}
.mb-point-125 {
  margin-bottom: 0.125rem;
}

.z20 {
  z-index: 20;
}
.text-transform-none {
  text-transform: none !important;
}
.upload-report-form .form-group {
  margin-bottom: 0;
  margin-right: 10px;
}
.plan-comma ~ .plan-comma::before {
  content: ', ';
}

.remove-coach-popup {
  @include gray-scrollbar;
  table tbody {
    display: block;
    max-height: 20vh;
    overflow-y: overlay;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.view-profile-btn {
  @include common-btn-style;
}
.bg-yellow-manager {
  background: #dcb232;
}
.text-yellow-manager {
  color: #dcb232;
}
.bg-blue-manager {
  background: #345995;
}
.text-color-blue-manager {
  color: #345995;
}
.text-color-light-blue-manager {
  color: #69b1d8;
}
.bg-light-blue-manager {
  background: #69b1d8;
}
.mt-3-25 {
  margin-top: 3.25rem;
}
.pb-3-813 {
  padding-bottom: 3.813rem !important;
}
.pb-point-5 {
  padding-bottom: 0.5rem !important;
}
.pt-point-5 {
  padding-top: 0.5rem !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.m-0-auto {
  margin: 0 auto !important;
}
.m-auto-0 {
  margin: auto 0 !important;
}
.border-bottom-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.leader-select-options-wrp {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.leader-select {
  .ab-select__value-container {
    padding-left: 0;
  }
  .ab-select__placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    padding-left: 1rem;
    margin-left: 0;
  }
}
@media (min-width: 426px) and (max-width: 1025px) {
  .leader-select {
    width: 50%;
  }
}

.rotate-original-position {
  transform: rotate(0deg);
  transition: transform 0.35s linear;
}
.text-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.journey-media-list {
  & ol {
    padding-inline-start: 1.125rem;
  }
}

.jurneys-audio-list {
  & .col-first {
    flex: 0 0 3.53%;
    max-width: 3.53%;
  }
  & .col-second {
    flex: 0 0 85.33333%;
    max-width: 85.33333%;
  }
  & .col-third {
    flex: 0 0 8.068335%;
    max-width: 8.068335%;
  }
  & .col-fourth {
    flex: 0 0 3.068335%;
    max-width: 3.068335%;
  }
}

.notes {
  @include hide-scrollbar;
  & .note-input-section {
    margin-bottom: 3.75rem;
  }
  & .notes-input {
    padding: 0.938rem;
    border: 1px solid #000000;
    background: #fafafa;
    border-radius: 20px;
    resize: none;
  }
}
.media-list-divider {
  border-top: 0.5px solid #000000;
}
.media-list-cursor-drag {
  cursor: move !important;
}
.search-input {
  border: 1px solid #000000;
}

.journeys-media-list {
  @include media-list-style(290px);
}
.leader-wrapper {
  @include media-list-style(50px);
}

.objectives-title {
  margin-top: 2.063rem;
  margin-bottom: 1.063rem;
}
.org-objective {
  margin-top: 0.75rem;
}

.hide-scrollbar {
  @include hide-scrollbar;
}

.resize-none {
  resize: none;
}

.values-principles {
  margin-top: 1.688rem;
  margin-bottom: 3.75rem;
}

.purpose-vision-form {
  & .delete-link {
    top: 0.25rem;
    color: map-get($red, '800');
    &:hover {
      text-decoration: none !important;
    }
  }
  & .add-input:hover {
    rect {
      fill-opacity: 1;
    }
  }
}

.allover-score-section {
  margin-top: 1.188rem;
  margin-bottom: 3.75rem;

  & .manager-score {
    line-height: 3.563rem;
  }
}

.font-42 {
  font-size: 2.625rem !important;
}

.notification-icon {
  @include header-icon-styles(0.7, map-get($saffron, '300'));
}

.help-icon {
  @include header-icon-styles(0.7, map-get($saffron, '300'));
}

.user-profile-icon {
  @include header-icon-styles(0.7, map-get($saffron, '300'));
}

.header-drop-down {
  background: map-get($gray, '104');
  border: 1px solid #000000;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.objectives {
  .objectives-form {
    @include hide-scrollbar;
    & .into-area {
      padding: 0.938rem;
      border: 1px solid #000000;
      background: #fafafa;
      border-radius: 20px !important;
      resize: none;
    }
  }
  & .objectives-input {
    margin-bottom: 1.375rem;
  }
}

.cursor-move {
  cursor: move;
}

.journeys-wrapper {
  margin-bottom: 4.5rem;
  .nav-tabs {
    border: none;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.journeys-tabs {
  @include tabs-horizontal-scroll;
  & .nav-link {
    @include page-tabs(
      $black,
      600,
      0.9rem 1rem,
      'Montserrat',
      map-get($gray, '104'),
      0.762rem,
      0.625rem,
      0.25rem
    );
    text-transform: uppercase;
    &.active,
    &:hover {
      color: $white;
      background-color: map-get($saffron, '300');
      font-weight: 700;
    }
    margin-bottom: 0rem !important;
  }
  &.nav-tabs {
    border-bottom: none !important;
  }
}

.mr-10-px {
  margin-right: 0.625rem;
}
.group-journey-dropdown {
  margin-top: 1.5rem;
  .ab-select__control {
    border-width: 1px;
    border-color: #000;
    border-style: solid;
    height: 1.75rem;
    &:hover {
      border-color: #000;
    }
  }
  .ab-select__single-value,
  .ab-select__option {
    color: #000;
    font-size: 0.875rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.02em;
    &:active {
      background-color: unset;
    }
    margin: 0;
    padding: 0.5rem 0 0 0.813rem;
  }
  .ab-select__value-container {
    cursor: pointer;
    padding: 0;
  }
  .ab-select__single-value {
    padding-top: 0;
  }
}

.org-user-list {
  @include search-input(98.5%, 165px);
}
.delete-user-list {
  @include search-input(97.5%, 165px);
}
.user-search-input {
  height: 42px;
}

.notification-dropdown {
  background: #fff;
  border: 1px solid #000000;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.35);
}

.notification-wrapper {
  @include customize-scrollbar;
  & .notification-section {
    max-height: 16.5rem;
    overflow-y: overlay !important;
    overflow-y: scroll;
  }
}

.notification-section {
  .notification-title {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    &:hover {
      font-weight: 700 !important;
      text-decoration: none !important;
    }
  }
}

.notification-counter {
  right: -6px;
  top: -7px;
  width: 16px;
  height: 16px;
  background-color: map-get($saffron, '300');
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  & .count {
    line-height: 0.625rem;
    position: relative;
    display: grid;
    height: 100%;
    place-content: center;
  }
}

.jurneys-audio-list {
  & .journeys-col-first {
    flex: 0 0 6.598335%;
    max-width: 6.598335%;
  }
  & .journeys-col-second {
    flex: 0 0 85.33333%;
    max-width: 85.33333%;
  }
  & .journeys-col-third {
    flex: 0 0 8.068335%;
    max-width: 8.068335%;
  }
}

.disabled-options {
  cursor: not-allowed !important;
  opacity: 0.25 !important;
}
.user-plan-dropdown .form-group {
  margin-bottom: 0rem !important;
}
.remove-paywall-btn {
  padding: 0.5rem !important;
  font-size: 0.5rem !important;
}
.org-actions-btn {
  width: 15.75rem;
}
.org-dropdown-menu {
  background: #fff !important;
  border: 1px solid #000000 !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.35) !important;
  & .dropdown-item:hover {
    background-color: transparent !important;
  }
  & .dropdown-item:active {
    background-color: map-get($saffron, '300') !important;
  }
}

.user-main-contact-list {
  @include search-input(97.5%, 165px);
  overflow-y: scroll;
  li {
    padding: 3px;
    &:hover {
      background-color: #428bca !important;
      cursor: pointer;
    }
  }
}
.view-member-attendance {
  & tbody {
    height: 9.3rem;
    max-height: 9.3rem !important;
    overflow-y: auto;
    overflow-y: overlay !important;
  }
}
.leftside-checkbox-label {
  &.custom-control-label::before {
    right: -1.5rem !important;
    left: unset !important;
  }
  &.custom-control-label::after {
    right: -1.5rem !important;
    left: unset !important;
  }
}
@include media-breakpoint-up(sm) {
  .second-attendance-section {
    border-left: 1px solid #000;
  }
}
.staff-coach-indicator {
  width: 1.5rem;
  height: 1.5rem;
}
.staff-coach-table-indicator {
  width: 0.75rem;
  height: 0.75rem;
}
//confirm coach-type popup style (coach profile page)
.confirm-coach-type-popup {
  & .modal-dialog {
    width: 90%;
    max-width: none !important;
  }

  & table tbody {
    max-height: none !important;
  }
}
.staff-coach-table {
  .popup-fields .form-group {
    margin-bottom: 0rem !important;
  }
}
.resize-image {
  bottom: 24px;
  right: 24px;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.border-radius-20 {
  border-radius: 20px;
}
.audio-description {
  margin-top: 0.438rem;
  color: rgba(0, 0, 0, 0.75);
}
.bg-custom-gray {
  background-color: #f3f5f5;
}
.next-module {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.banner-image {
  background-color: map-get($gray, '100');
}

.user-plan-section .form-group {
  margin-bottom: 0 !important;
}
.timezone-field__value-container {
  height: 32px;
}
.save-sow-table {
  & th {
    border-bottom: 1px solid map-get($gray, '100') !important;
  }
  & .tbl-last-child {
    border-bottom: none !important;
  }
  & .table th,
  .table td {
    padding: 0.5rem !important;
  }
}
.platform-header {
  height: 120px;
}
.form-control {
  padding: 0.375rem 0.813rem;
}
.form-control-lg {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02em !important;
  padding: 8px 18px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px !important;
  color: #000 !important;
  &::placeholder {
    color: #000 !important;
  }
}
.form-control-md {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 0.75rem !important;
  line-height: 1.25rem !important;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px !important;
  padding: 6px 13px;
  color: #000 !important;
  &::placeholder {
    color: #000 !important;
  }
}
.form-control-sm {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 0.75rem !important;
  line-height: 1.25rem !important;
  border: 1px solid #000000;
  padding: 3px 12px;
  color: #000 !important;
  &::placeholder {
    color: #000 !important;
  }
}

@include media-breakpoint-up(lg) {
  .continue-breakthrought .shimmer-title-line {
    height: 36px;
  }
}
@include media-breakpoint-down(lg) {
  .continue-breakthrought .shimmer-title-line {
    height: 26px;
  }
}
.shimmer-thumbnail {
  min-width: 48px;
}
.sow-modal {
  @include customize-scrollbar;
}
// apply option style
option {
  font-weight: normal !important;
}
.remove-coach-privileges {
  @include customize-scrollbar;
}
.attendance-container {
  @include customize-scrollbar;
  & .attendance-checkboxes {
    overflow: overlay !important;
    overflow: scroll;
    max-height: 600px;
  }
}
.sow-user-filters {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 0.65 !important;
  }
}
.promo-accordion {
  & .accordion > .card {
    border-radius: 0.25rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
}
.normal-padding-modal {
  & .modal-body {
    padding: 1rem !important;
  }
}
.calender-setup-checkbox,
.wisdom-enabled-checkbox {
  & .custom-control-label {
    @extend .s6;
    text-align: left;
  }
}
.calendly-booking-tooltip {
  cursor: pointer;
  & .arrow {
    display: none !important;
  }
  & .tooltip-inner {
    opacity: 1;
    @extend .s6;
    padding: 1rem !important;
    border-radius: 0.625rem !important;
  }
}
.visibility-hidden {
  visibility: hidden !important;
}
.assign-btn {
  width: 10.941rem;
}
.categories-takeaways {
  margin-top: 2.688rem;
  @include customize-scrollbar;
  & .categories-notes {
    overflow-y: overlay !important;
    overflow-y: scroll;
    padding-right: 1.625rem !important;
    height: -moz-calc(100vh - 230px); /* Firefox */
    height: -webkit-calc(100vh - 230px); /* Chrome, Safari */
    height: calc(100vh - 230px); /* IE9+ and future browsers */
  }
  & .review-description {
    margin-bottom: 1.188rem;
  }
  & .takeaways {
    padding-left: 1.25rem;
  }
}
.review-title {
  line-height: 26px;
  letter-spacing: -0.88px;
}
.growth-step-btn {
  bottom: 2%;
}
.explore-offerings {
  margin-top: 2.688rem;
  padding: 0 0.75rem;
}
.view-growth-plan {
  & .growth-label {
    width: 7.5rem;
  }
  & .view-gp-subtitle {
    margin-top: 2.625rem;
  }
}
.session-practice-wrapper {
  & .review-description {
    margin-bottom: 1.375rem;
  }
}
.note-complete-indicator {
  margin-right: 1.167rem;
}
.outline-dark-btn {
  font-size: 0.5rem !important;
  font-family: 'Montserrat' !important;
  font-weight: 700 !important;
  letter-spacing: 1.6px !important;
  padding: 6px 11px !important;
}
.outcome-selection {
  margin: 12px 0px;
  border: 1px solid #000;
  padding: 9px 14.67px 7px 22px;
  &.active {
    border: 2px solid map-get($green, '100');
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
.add-gp-btn {
  margin-left: 0.688rem;
}
.vh-65 {
  height: 65vh;
}
.rotate-minus-90 {
  transform: rotate(-90deg);
  transition: transform 0.35s linear;
}
.payment-clear-filter {
  padding-top: 0.813rem;
}
.group-clear-filter {
  margin-top: 0.125rem;
}
.custom-text-uppercase {
  text-transform: uppercase;
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  &::placeholder {
    /* Recent browsers */
    text-transform: none;
  }
}
.w-30 {
  width: 30%;
}
.custom-dropdown-link {
  padding-left: 1.3rem;
}
.return-link:hover {
  font-weight: 700;
}
