@each $name, $colors in $custom-colors {
  @each $number, $color in $colors {
    .text-#{$name}-#{$number} {
      color: $color !important;
    }
    .bg-#{$name}-#{$number} {
      background-color: $color;
    }
    .fill-#{$name}-#{$number} {
      fill: $color;
    }
    .hover\:fill-#{$name}-#{$number} {
      &:hover {
        fill: $color;
      }
    }
    .hover\:text-#{$name}-#{$number} {
      &:hover {
        color: $color !important;
      }
    }
  }
}

.hover\:bg-gray-101 {
  &:hover {
    background-color: map-get($gray, '101');
  }
}

.fill-none {
  fill: none;
}

.hover\:fill-white {
  &:hover {
    fill: $white;
  }
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}
.font-style-normal {
  font-style: normal;
}
