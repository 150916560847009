.tooltip-icon-container {
  background-color: $white;
  width: 40px;
  height: 40px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(100, 100, 100, 0.3);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  margin: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  40% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.7);
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(100, 100, 100, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(100, 100, 100, 0);
  }
}

.popover-icon {
  width: 60px;
}

@include media-breakpoint-down(lg) {
  .self-awareness-tooltip-icon {
    bottom: -2rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .breakthrough-tooltip-icon {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -33px;
  }
  .self-reflection-help {
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .tooltip-icon-container {
    width: 30px;
    height: 30px;
  }
  .popover-icon {
    width: 21px;
  }
  .self-awareness-tooltip-icon {
    right: 8%;
    bottom: -1rem;
  }
  .breakthrough-tooltip-icon {
    right: -22px;
    top: -23px;
  }
  .self-reflection-help {
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: -3rem;
  }
}

.tooltip-icon {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.sidebar-tooltip-icon {
  right: -25px;
  top: 4.5rem;
}

.sidebar-programs-tooltip-icon {
  right: -25px;
  top: 20rem;
}

.team-cohesion-tooltip-icon {
  right: -25px;
  top: -25px;
}

.xs-awareness-tooltip-icon {
  bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.xs-header-tooltip-icon {
  bottom: -2.2rem;
  right: 0.7rem;
}

.popover .arrow {
  display: none !important;
}
