.user-payment {
  max-width: 424px;
  margin: 0 auto;
  & .title {
    margin-bottom: 10px;
  }
  .promo {
    .form-control {
      border: 1px solid darkgrey !important;
      border-right: none !important;
      background: #ffffff;
      font-size: 10pt;
      color: $black;
    }
    .promo-text {
      bottom: -1.8rem;
    }
  }
  .promo-group {
    margin-bottom: 7px;
  }
  label {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    color: $black !important;
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    letter-spacing: 0.25em !important;
  }
  & .make-payment {
    height: 40px;
  }
  .form-control {
    background: #ffffff !important;
    border: 1px solid #000000 !important;
    border-radius: 3px !important;
    font-size: 12pt;
    color: $black;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: #b22222 !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: #509f92 !important;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: map-get($dark, '900-5') !important;
      opacity: 0.5 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: map-get($dark, '900-5') !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: map-get($dark, '900-5') !important;
    }
  }

  .summary-title {
    padding: 5px 10px;
    border-bottom: 1px solid #000000;
  }
  .promo-code-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }
  .promo-details {
    padding: 0 10px;
    margin-left: 0 !important;
  }
  .promo-code-text {
    margin: 0 10px;
    width: 50%;
  }
  .promo-code-label div {
    font-size: 14px;
    font-weight: 600;
  }
  .payment-details-form-wrp {
    padding: 20px 0;
  }
  .expiration-text {
    width: 40%;
    /* display: inline-block; */
  }
  #card-exp {
    display: none;
  }
  .padding-handle {
    padding: 0 10%;
  }
  .question-icon {
    height: 30px;
    width: 30px;
    margin-left: 3px;
  }
  .credit-card-wrp {
    position: relative;
  }

  .payment-form-label {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    flex-basis: 115px;
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .custom-templates ul li {
    font-size: 11px;
    font-weight: 600;
  }
  .cart {
    border: 1px solid #333;
  }

  .cbody {
    background-color: #ffffff;
  }

  .cbody h4 {
    margin-left: 4px;
  }

  .cbody p {
    margin-left: 4px;
  }

  .cfooter {
    background: #808080;
    color: #fff;
    padding: 0.3em 1.5em;
  }

  .cfooter p {
    margin: 5px;
    font-weight: 800;
  }

  .cfooter > p > span {
    float: right;
  }
  .apply-button {
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-radius: 0;
  }
  .btn.btn-primary {
    color: #fff;
    background: #000000;
    border: 2px solid #333333;
  }
  .apply-button button {
    font-size: 12px !important;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.1s ease;
  }
  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-primary:focus {
    background: #fff;
    color: #333333;
    border-color: #333333;
    box-shadow: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .btn.btn-primary:hover i.arrow {
    border-color: #333333;
  }

  .btn.btn-primary.btn-clear {
    background: none;
    color: #333333;
  }

  .btn.btn-primary.btn-clear:hover {
    background: #333333;
    color: #fff;
  }

  .btn.btn-primary.btn-header {
    padding: 9px;
    margin: 5px 20px;
    border-color: #fff;
    width: 180px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-header:hover {
    background: #fff;
    color: #333333;
    font-weight: 300;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    -webkit-border-radius: 300px;
    border-radius: 5px;
    background-clip: padding-box;
    font-size: 14px !important;
    letter-spacing: 3px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-clear.disabled:hover {
    background: none;
    color: #333333;
  }

  .payment-details-form-wrp {
    padding: 20px 0;
  }
  .checkout-form {
    width: 100%;
  }
  .form-label {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    flex-basis: 115px;
  }

  label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 16px;
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .custom-templates .form-control {
    margin: 0;
    border-radius: 5px;
    border: 1px solid darkgray;
  }

  .expiration-text {
    width: 40%;
  }
  .cvv-text {
    width: 60%;
    display: inline-block;
  }
  @media (max-width: 767px) {
    .padding-handle {
      padding: 0 15px;
    }
  }

  @media (max-width: 600px) {
    .promo-code-wrp {
      display: block;
    }
    .promo-code-text {
      margin: 10px 0;
      width: 100%;
    }
    .apply-button {
      text-align: left;
    }
  }

  @media only screen and (max-width: 496px) {
    .assessment-button {
      width: 100% !important;
    }
    .cfooter {
      padding: 0;
    }
  }
}
.mb-2-rem {
  margin-bottom: 2rem !important;
}
.summary-separator {
  border-bottom: 1px solid #000000;
  margin: 0.75rem 0;
}
.form-control.rounded-right-0 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.apply-promo-button.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.apply-promo-button {
  width: 8.75rem;
  height: 2rem;
}
.summary-label {
  font-family: Montserrat !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
}
.summary-text {
  font-family: Open Sans !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02em !important;
}
.forward-slash-label {
  width: 6px;
  height: 12px;
  margin-bottom: 0px !important;
}
@include media-breakpoint-up(lg) {
  .payment-details {
    margin-top: 2rem !important;
  }
}
// multi range slider
.slider-wrapper {
  .slider__track,
  .slider__range {
    height: 5px;
  }

  .slider__track {
    background-color: #ced4da;
    z-index: 1;
  }

  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }

  .slider__left-value {
    left: 6px;
  }

  .slider__right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 95%;
    outline: none;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    @include range-slider-thumb(#f1f5f7);
  }
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    @include range-slider-thumb(#f1f5f7);
  }
  .slider-range-input {
    width: 5.5rem;
  }
  .remove-right-border {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
}
//stripe card style
.card-labels {
  margin-bottom: 5px !important;
}

.payment-details .StripeElement {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 3px !important;
  font-size: 12pt;
  color: $black;
  // height: 32px;
  & ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important; /* Firefox */
  }

  & :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }

  & ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }
  &.StripeElement--invalid {
    border-color: #b22222 !important;
  }
  &.StripeElement--invalid.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 76, 106, 0.25);
  }
  &.StripeElement--complete {
    border-color: #509f92 !important;
  }
  &.StripeElement--complete.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 159, 146, 0.25);
  }
}
.credit-card-shimmer {
  height: 10rem !important;
}
.input-shimmer {
  & .shimmer-title-line {
    height: 2rem !important;
  }
}
.add-card-link {
  text-decoration: underline !important;
}
.highlight-row {
  background-color: map-get($green, '400') !important;
}
.promo-return-btn {
  &:hover:enabled {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.subscription-title,
.payment-history-title {
  margin-top: 3.25rem;
  margin-bottom: 1.375rem;
}
.payment-modal {
  .modal-content {
    max-width: 29.438rem;
    border-radius: 10px;
    background: $white;
    border: none;
  }
  .payment-modal-header {
    border-radius: 5px 5px 0px 0px;
    background: map-get($blue, '500');
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .payment-modal-title {
    font-family: Montserrat;
    font-size: 0.625rem; //10px
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; //16px
    letter-spacing: 0.125rem; //2px
    color: map-get($saffron, '300');
    text-transform: uppercase;
  }
  .payment-modal-body {
    padding: 1.313rem 1.188rem 2.25rem 1.375rem;
  }
  .confirmation-title {
    font-family: Open Sans;
    font-size: 1.25rem; //20px
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem; //30px
    letter-spacing: -0.025rem; //-0.4px
  }
  .confirmation-subtitle {
    font-family: Open Sans;
    font-size: 1rem; //16px
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; //28px
    letter-spacing: -0.02rem; //-0.32px
  }
}
