.account {
  .caption-top {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: center;
    caption-side: top;
  }
  .btn.btn-primary {
    color: #fff;
    background: #000000;
    border: 2px solid #333333;
    &:hover,
    &:active,
    &:focus {
      background: #fff;
      color: #333333;
      border-color: #333333;
      box-shadow: none;
    }
    &:disabled {
      background-color: #333333;
      color: white;
    }
  }

  .btn.btn-primary:hover i.arrow {
    border-color: #333333;
  }

  .btn.btn-primary.btn-clear {
    background: none;
    color: #333333;
  }

  .btn.btn-primary.btn-clear:hover {
    background: #333333;
    color: #fff;
  }

  .btn.btn-primary.btn-header {
    padding: 9px;
    margin: 5px 20px;
    border-color: #fff;
    width: 180px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-header:hover {
    background: #fff;
    color: #333333;
    font-weight: 300;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    -webkit-border-radius: 300px;
    border-radius: 5px;
    background-clip: padding-box;
    font-size: 14px !important;
    letter-spacing: 3px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-clear.disabled:hover {
    background: none;
    color: #333333;
  }
  button.btn.btn-primary.btn-small {
    -webkit-border-radius: 300px;
    border-radius: 3px;
    background-clip: padding-box;
    font-size: 10px !important;
    letter-spacing: 2px;
    font-weight: 200;
    padding: 0px 5px;
  }
  .report-coach {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }
  .profile-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .custom-report-container {
    margin-bottom: 20px;
  }
}

.account-daterange {
  margin: 0;
  border-radius: 5px;
  padding-right: 0;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ccc !important;
  background: #fafafa !important;
  font-family: 'Montserrat', 'Open Sans';
  line-height: normal;
  box-sizing: border-box;
  &.disabled-field {
    background: #e9ecef !important;
  }
}
