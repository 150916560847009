.user-show-title-wrp {
  padding: 40px 0;
  margin-bottom: 20px;
  background: #eeeeee;
}
.user-show-title-wrp h3 {
  margin: 0;
  font-weight: 700;
  font-size: 21pt;
}
.user-show-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coach-dashboard {
  .page-heading {
    margin-bottom: 44px;
  }
}

.width-100 {
  width: 100%;
}

.form-component-wrp {
  display: flex;
  align-items: center;
}

.timeliner-wrp {
  margin-bottom: 50px;
}
.timeline-presentation {
  width: 16.3%;
  display: inline-block;
  vertical-align: top;
}
.timeline {
  position: relative;
  height: 35px;
  margin-bottom: 20px;
}
.timeline:before {
  content: '';
  position: absolute;
  height: 15px;
  width: 98%;
  background-color: #c2c2c2;
  transform: skewX(30deg);
}
.timeline:after {
  content: '';
  position: absolute;
  height: 15px;
  width: 98%;
  background-color: #858585;
  transform: skewX(-30deg);
  top: 18px;
}

.timeline-desc-label {
  margin-bottom: 15px;
  font-weight: 600;
  min-height: 40px;
}
.timeline-desc-wrp {
  padding-right: 10px;
}
.timeline-desc-label {
  color: #858585;
}
.timeline-description {
  color: #c2c2c2;
}
.timeline-active .timeline:before {
  background-color: #414141;
}
.timeline-active .timeline:after {
  background-color: #2f2f2f;
}
.timeline-active .timeline-desc-label {
  color: #2f2f2f;
}
.timeline-active .timeline-description {
  color: #414141;
}
.custom-templates {
  flex: 1;
  margin: 0 0 0 20px;
}
.custom-templates .form-control {
  margin: 0;
  border-radius: 5px;
  border: 1px solid darkgray;
}
.custom-templates ul li {
  font-size: 11px;
  font-weight: 600;
}
.assessment-log {
  margin-left: 10px;
}
.form-wrp-profile {
  .form-label {
    margin: 0;
    font-weight: 600;
    flex-basis: 115px;
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

.custom-templates {
  flex: 1;
  margin: 0 0 0 20px;
}

.btn.btn-primary.compare-btn {
  color: #fff;
  background: #000000;
  border: 2px solid #333333;
  text-transform: uppercase;
  &:hover {
    background: #fff;
    color: #333333;
  }
  &:active,
  &:focus,
  &:active:focus {
    background: #000000;
    color: #fff;
    border: 2px solid #333333;
    box-shadow: none;
  }
}

.btn.btn-small.compare-btn {
  border-radius: 3px;
  background-clip: padding-box;
  font-size: 10px !important;
  letter-spacing: 2px;
  font-weight: 200;
  padding: 0px 5px;
}

.compare-btn-disabled {
  &:hover {
    color: #fff !important;
    background: #000000 !important;
  }
}
.client-profile-datepicker {
  padding: 0;
}
.coach-user-search {
  float: right;
  font-size: 12px;
  height: 32px;
  padding: 0 12px;
  border: 1px solid black;
  border-radius: 3px;
  outline: none;
}
.red {
  color: red;
  &:hover {
    color: red;
  }
}
.session-row {
  padding: 5px 10px 5px 10px;
}
