@import './mixin.scss';

.abroad-form-wrp {
  .form-control {
    border: 1px solid rgba(0, 0, 0, 0.7) !important;
    background: #fafafa;
    font-size: 12pt;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: #b22222 !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: #509f92 !important;
    }
  }
  &:hover .form-control {
    border: 1px solid rgba(0, 0, 0, 1) !important;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: black;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: black;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: black;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: black;
  }
  select:disabled {
    background-color: #c2c2c2 !important;
  }
}
.decoration-none {
  text-decoration: none;
}

.custom-table {
  tbody {
    .ab-select__dropdown-indicator {
      opacity: 0;
    }
    .ab-select__control {
      border-width: 1px;
      border-color: transparent;
      border-style: solid;
      min-height: calc(1.5em + 0.75rem + 2px);
    }
    .ab-select__clear-indicator {
      opacity: 0;
    }

    .hover,
    tr:hover {
      color: #212529;
      .ab-select__control {
        border-color: #000;
        min-height: calc(1.5em + 0.75rem + 2px);
      }
      .ab-select__dropdown-indicator {
        opacity: 1;
      }
      .ab-select__clear-indicator {
        opacity: 1;
      }
      input {
        border: 0.5px solid black;
        background-color: white !important;
        border-radius: 3px;
        pointer-events: auto;
        &:disabled {
          border: 1px solid transparent !important;
          background-color: transparent !important;
          pointer-events: none;
        }
      }
    }
  }
}

.border-visible tbody {
  .hover,
  tr:hover {
    input {
      border: 0.5px solid darkgrey !important;
    }
  }
  input,
  .form-control {
    border: 1px solid darkgrey !important;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: #b22222 !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: #509f92 !important;
    }
  }
}

.custom-table {
  .font-bold {
    font-weight: bold !important;
  }
  &.table-responsive {
    margin: 0 -8px;
    padding: 0 8px;
    width: calc(100% + 16px);
  }
  & tbody tr:hover {
    background-color: #f2f5f5 !important;
  }
  &.table-hover {
    tbody {
      tr {
        &:hover {
          tr {
            background-color: #f2f5f5 !important;
          }
        }
      }
    }
  }
  &.table {
    border: none;
    margin-bottom: 0px;

    tr,
    th,
    td {
      border: none;
      background-color: unset;
      color: black;
    }

    thead {
      tr th {
        @extend .s8;
        border-bottom: 1px solid black;
        text-align: left;
        padding: 0.5rem 0.563rem 0.5rem 0.75rem;
        &.text-right {
          padding-right: 0px;
        }
      }
      .header-data {
        &:first-child {
          padding-left: 0px;
        }
        &:not(:first-child) {
          padding-left: 1.5rem;
        }
      }
    }

    tbody {
      tr {
        td {
          @extend .s6;
          border-top: none;
          border-bottom: 1px solid #f2f5f5;
          padding: 0.5rem 0.563rem 0.5rem 0.75rem;
          text-align: left;
          vertical-align: middle;
          input {
            font-family: 'Open Sans';
            font-size: 0.75rem;
            font-weight: 500;
            font-style: normal;
            line-height: 0.875rem;
            color: #000;
          }
          a {
            color: #bf9000;
            text-decoration: none;

            &:hover {
              font-weight: 700;
            }
          }
          .right-aligned.form-group > div {
            text-align: right;
          }
        }
      }
    }
  }
}
.enterprise-sow-table,
.user-table {
  tbody {
    input {
      border: 1px solid black;
      background-color: white;
      border-radius: 3px;
      pointer-events: auto;
      padding: 0.375rem 0.75rem !important;
      &.is-invalid {
        border-color: #b22222 !important;
      }
      .was-validated &:valid,
      &.is-valid {
        border-color: #509f92 !important;
      }
      &:disabled {
        pointer-events: none;
        border: 1px solid transparent;
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
  }
}

.page-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.313rem;
  align-items: center;
  color: #000000;
  letter-spacing: -0.06em;
}

.active-class {
  color: #efc030 !important;
}

.back-icon-container {
  margin-left: -2.188rem;
}

.header-sub-label {
  background-color: #f5f5f5;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}

.user-input {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 0.75rem !important;
  line-height: 1.25rem;
}
.page-header {
  margin-bottom: 2.5rem;
}
.header-tooltip {
  z-index: 10 !important;
  & .arrow,
  .tooltip-arrow {
    display: none !important;
  }
  & .tooltip-inner {
    letter-spacing: -0.02em !important;
    border-radius: 0.625rem !important;
  }
}

.header-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  @include tabs-horizontal-scroll;
  .nav-item {
    .nav-link {
      @include page-tabs(
        $black,
        600,
        0.875rem 1.5rem,
        'Montserrat',
        map-get($gray, '104'),
        0.762rem,
        0.625rem,
        0.25rem
      );
      text-transform: uppercase;
      &.active,
      &:hover {
        color: $white;
        background-color: map-get($saffron, '300');
        font-weight: 700;
      }
      &:focus-visible,
      &.active {
        outline: none !important;
      }
    }
  }
}

.home-tabs {
  @include tabs-arrows-style(168px, -1rem, 0.7rem);
}

.notifications-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  @include tabs-horizontal-scroll;
  .nav-item {
    .nav-link {
      @include page-tabs(
        $black,
        600,
        0.625rem 1.5rem,
        'Open Sans',
        map-get($saffron, '300-3'),
        1.25rem,
        0.75rem,
        0rem
      );
      &:hover {
        color: $white;
        background-color: map-get($saffron, '300');
      }
      &.active {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &:focus-visible,
      &.active {
        outline: none !important;
      }
    }
  }
  //@include tabs-arrows-style;
}

.numbers-tabs {
  & .nav {
    @include tabs-horizontal-scroll;
    border-bottom: none !important;
    flex-wrap: nowrap;
    overflow: auto;
  }
  & .nav-link {
    @include page-tabs(
      $black,
      600,
      0.375rem 2.53rem,
      'Montserrat',
      map-get($gray, '104'),
      0.75rem,
      0.625rem,
      0.25rem
    );
    border-radius: 0.75rem;
    &.active,
    &:hover {
      color: $white;
      background-color: map-get($dark, '900');
      font-weight: 700;
    }
    &:focus-visible,
    &.active {
      outline: none !important;
    }
  }
  @include tabs-arrows-style(5px, -1rem, 0.5rem);
}

.letter-spacing-minus-04 {
  letter-spacing: -0.04rem;
}
.letter-spacing-minus-02 {
  letter-spacing: -0.02rem;
}
.letter-spacing-02 {
  letter-spacing: 0.02rem;
}
.letter-spacing-2 {
  letter-spacing: 0.2rem;
}
.leading-7-5 {
  line-height: 1.875rem;
}
.mt-3-5 {
  margin-top: 3.5rem;
}
.mb-3-5 {
  margin-bottom: 3.5rem;
}
.mb-36 {
  margin-bottom: 2.25rem;
}
.py-3-5 {
  padding: 3.5rem 0 3.5rem 0;
}
.py-3-25 {
  padding: 3.25rem 0 3.25rem 0;
}
.font-12pt {
  font-size: 12pt;
}
.font-18pt {
  font-size: 18pt;
}
.font-13 {
  font-size: 0.813rem;
}
.leading-4-5 {
  line-height: 1.125rem;
}
.leading-3-5 {
  line-height: 0.875rem;
}
.px-075 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.pr-2rem {
  padding-right: 2rem;
}
.p-075 {
  padding: 0.75rem;
}
.bottom-accordion {
  margin-top: 2.625rem;
  & .polygon-icon {
    width: 1.5rem !important;
    height: 1.563rem !important;
    margin-left: 0.75rem;
  }
  & .manager-polygon-icon {
    width: 1rem !important;
    height: 1rem !important;
    margin-left: 0.5rem;
  }
}

.card-swiper {
  & .swiper-slide {
    width: 12.438rem !important;
    //height: 12.438rem !important;
  }
}

.page-sections-text {
  line-height: 2.25rem;
  letter-spacing: -0.04rem;
}

.font-white {
  color: #fff;
}
.border-x-10 {
  border-radius: 10px 10px 0px 0px;
}

@include media-breakpoint-up(lg) {
  .header-tooltip {
    & .arrow {
      display: none !important;
    }
    & .tooltip-inner {
      letter-spacing: -0.02em !important;
      border-radius: 0.625rem !important;
    }
  }
  .header-tooltip {
    .tooltip-inner {
      padding: 1.25rem 2.375rem 1.25rem 2.375rem !important;
      max-width: 23.25rem !important;
    }
  }
  .swiper-button-next {
    right: -1.5rem !important;
  }
  .swiper-button-prev {
    left: -1.5rem !important;
  }
  .recommended-audios {
    padding: 1.938rem 0;
  }
  .pl-lg-40 {
    padding-left: 2.5rem !important;
  }
  .mt-lg-32 {
    margin-top: 2rem !important;
  }
  .mb-lg-36 {
    margin-bottom: 2.25rem !important;
  }
  .p-lg-2rem {
    padding: 0 2rem;
  }
  .pl-lg-075 {
    padding-left: 0.75rem;
  }
  .help-dropdown {
    transform: translate(36px, 11px) !important;
  }
  .notification-dropdown {
    width: 400px;
    transform: translate(69px, 11px) !important;
  }
}
@include media-breakpoint-down(lg) {
  .tab-button-next {
    margin-right: 2rem;
  }
  .tab-button-prev {
    margin-left: 2rem;
  }
  .px-md-30 {
    padding: 0 1.875rem !important;
  }
  .recommended-audios {
    padding: 1.938rem 1.8rem !important;
  }
  .help-dropdown {
    transform: translate(74.5px, 11px) !important;
  }
  .sidebar-close-dropdown {
    &.notification-dropdown {
      transform: translate(57.5px, 11px) !important;
    }
  }
  .sidebar-open-dropdown {
    &.notification-dropdown {
      transform: translate(130.5px, 11px) !important;
    }
  }
}
@include media-breakpoint-down(md) {
  .header-tooltip {
    .tooltip-inner {
      padding: 1.25rem 2.375rem 1.25rem 2.375rem !important;
      max-width: 23.25rem !important;
    }
  }
  .swiper-button-next {
    right: 1rem !important;
  }
  .swiper-button-prev {
    left: 1rem !important;
  }
  .page-sections-text {
    line-height: 1.625rem !important;
  }
  .page-header {
    margin-bottom: 1.5rem !important;
  }
}

@include media-breakpoint-down(sm) {
  .header-tooltip {
    .tooltip-inner {
      padding: 1rem 1.5rem 1rem 1.5rem !important;
      max-width: 95% !important;
    }
  }
  .px-sm-0 {
    padding: 0;
  }
  .w-sm-100 {
    width: 100%;
  }
  .aling-self-center-sm {
    align-self: center;
  }
}
.opacity-point-5 {
  opacity: 0.5;
}
.mt-2-5 {
  margin-top: 2.5rem;
}
.custom-mt-2-6 {
  margin-top: 2.688rem;
}
.custom-mt-2-375 {
  margin-top: 2.375rem;
}
.custom-mb-0-625 {
  margin-bottom: 0.625rem;
}
.custom-mb-1-25 {
  margin-bottom: 1.25rem;
}
.border-bottom-none {
  border-bottom: none !important;
}
.font-32 {
  font-size: 2rem;
}
.mr-2-rem {
  margin-right: 2rem;
}
.mt-38 {
  margin-top: 2.375rem;
}
.mb-38 {
  margin-bottom: 2.375rem;
}
.z-index-24 {
  z-index: 24;
}

.pr-10-percent {
  padding-right: 10%;
}
.flex-shrink-10 {
  flex-shrink: 10;
}
.w-12-half {
  width: 12.5rem;
}
.font-15 {
  font-size: 0.938rem;
}
.pb-5px {
  padding-bottom: 0.313rem;
}
.searchbox-wrapper {
  @include customize-scrollbar;
}
.search-dropdown {
  position: absolute;
  transform: translate(-11px, 143px) !important;
  width: 21.688rem;
  background: #fff;
  border: 1px solid #000000;
  border-top: 0;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.35);
  transition: 0.4s;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0 0.625rem;
}
.platform-search-input {
  border: 1px solid map-get($dark, '900-5');
  color: map-get($dark, '900-5');
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.5;
  & .border-bottom-none {
    border-bottom: 0 !important;
  }
  &.hover {
    opacity: 1;
    border: 1px solid map-get($dark, '900');
    color: map-get($dark, '900');
  }
  &.active {
    width: 21.688rem;
    transition: 0.4s;
  }
  &.unActive {
    width: 6.5rem;
    transition: 0.4s;
  }
  &.show-search-result {
    -webkit-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
  }
  & input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    height: 1.375rem !important;
  }
  & .search-section {
    border-top: 1px solid #000000;
    & .search-container {
      height: 15.5rem;
      max-height: 15.5rem !important;
      overflow-y: overlay !important;
      overflow-y: scroll;
    }

    & .search-link {
      text-decoration: none !important;
      &:hover {
        font-weight: 700 !important;
      }
    }
  }
}
.w-15 {
  width: 15rem;
}
.field-disabled {
  background-color: map-get($gray, '400') !important;
}
.z-1051 {
  z-index: 1051 !important;
}

.display,
.s1 {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 2rem !important;
  line-height: 2.438rem !important;
  letter-spacing: -0.02em !important;
}

.heading,
.s2 {
  font-family: Open Sans !important;
  font-weight: 700 !important;
  font-size: 1.75rem !important;
  line-height: 2.25rem !important;
  letter-spacing: -0.04em !important;
}

.sub-heading,
.s3 {
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  letter-spacing: -0.02em !important;
}

.mini-display,
.s4 {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  letter-spacing: 0.2em !important;
}

.paragraph,
.s5 {
  font-family: Open Sans !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.75rem !important;
}

.small-paragraph,
.s6 {
  font-family: Open Sans !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02em !important;
}

.small-description,
.s7 {
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 0.75rem !important;
  line-height: 1.25rem !important;
}

.btn-inactive,
.s8 {
  font-family: Montserrat !important;
  font-weight: 600 !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
}

.btn-active,
.s9 {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
}
.w-40 {
  width: 40%;
}
select {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'> <path fill-rule='evenodd' clip-rule='evenodd' d='M9.28033 0.214404C9.57322 0.500276 9.57322 0.963765 9.28033 1.24964L5.28033 5.15375C4.98744 5.43962 4.51256 5.43962 4.21967 5.15375L0.21967 1.24964C-0.0732233 0.963766 -0.0732233 0.500276 0.21967 0.214404C0.512563 -0.0714676 0.987437 -0.0714677 1.28033 0.214404L4.75 3.6009L8.21967 0.214404C8.51256 -0.071468 8.98744 -0.071468 9.28033 0.214404Z' fill='black'/> </svg>")
    no-repeat !important;
  background-position: calc(100% - 0.813rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

table {
  @include custom-checkbox;
}
.line-height-normal {
  line-height: normal !important;
}
.z1200 {
  z-index: 1200;
}
.coach-profile-input__value-container {
  height: auto !important;
  min-height: 39px !important;
  padding: 2px 18px !important;
  @extend .form-control-lg;
}
.paid-session-input {
  @extend .form-control-lg;
}
.black-color-placeholder {
  &::placeholder,
  & ::placeholder {
    color: #000 !important;
  }
}
.black-input-border {
  border: 1px solid #000 !important;
  border-color: #000 !important;
}
.selection-input__value-container {
  background-color: #fff !important;
}

.timezone-field-disabled__value-container {
  @include timeZoneField;
  @include form-control-lg('#c2c2c2');
}

.timezone-field__value-container {
  @include timeZoneField;
  @include form-control-lg('#fff');
}

.sentence-case-text {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase !important;
  }
}
.custom__modal {
  & .modal-dialog {
    width: 60vw !important; /* Occupy the 60% of the screen width */
    max-width: 60vw !important;
  }
}
.vertical-align-top {
  vertical-align: top !important;
}
.custom-text-truncate {
  @include custom-text-truncate(95%);
}
.table-truncate-text {
  @include custom-text-truncate(100%);
}
.is-disabled {
  &.form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef !important;
  }
  & .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef !important;
  }
}
@media print {
  .compare-date {
    display: block !important;
    width: 100% !important;
    margin: 0 auto !important;
    text-align: left !important;
  }

  .ab-select__control {
    width: 100% !important;
  }
}
