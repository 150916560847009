.transition {
  @include transition-all;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  @include transition(transform 0.35s linear);
}

@include media-breakpoint-up(lg) {
  .progress-bar-enter {
    opacity: 1;
    transform: scale(0.9);
  }
  .progress-bar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .progress-bar-exit {
    opacity: 0;
  }
  .progress-bar-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  // .play-bar {
  //   height: 6rem;
  //   bottom: 0;
  //   right: 0;
  //   opacity: 1;
  // }

  .play-bar-enter {
    bottom: -6rem;
    opacity: 0;
  }
  .play-bar-enter-active {
    opacity: 1;
    bottom: 0;
    transition: opacity 500ms, bottom 500ms;
  }
  .play-bar-enter-done {
    opacity: 1;
    bottom: 0;
  }
  .play-bar-exit {
    bottom: 0;
    opacity: 1;
  }
  .play-bar-exit-active {
    opacity: 0;
    bottom: -6rem;
    transition: opacity 500ms, bottom 500ms;
  }
}

@include media-breakpoint-down(lg) {
  // .play-bar {
  //   min-height: 4rem;
  // }

  .play-bar-enter {
    bottom: -4rem;
    opacity: 0;
  }
  .play-bar-enter-active {
    opacity: 1;
    bottom: 0;
    transition: opacity 500ms, bottom 500ms;
  }
  .play-bar-enter-done {
    opacity: 1;
    bottom: 0;
  }
  .play-bar-exit {
    bottom: 0;
    opacity: 1;
  }
  .play-bar-exit-active {
    opacity: 0;
    bottom: -4rem;
    transition: opacity 500ms, bottom 500ms;
  }
  .layout-with-play-bar {
    padding-bottom: 4rem;
    transition: padding-bottom 500ms ease-out;
  }

  .sm-audio-control-enter {
    height: 0;
    transform: translateY(8.313rem);
  }
  .sm-audio-control-enter-active {
    height: 8.313rem;
    transform: translateY(0);
    transition: all 500ms;
  }

  .sm-audio-control-exit {
    height: 8.313rem;
    transform: translateY(0);
  }
  .sm-audio-control-exit-active {
    height: 0;
    transform: translateY(8.313rem);
    transition: all 500ms;
  }
}

.page-enter {
  opacity: 1;
  // transform: scale(0.9);
}
.page-enter-active {
  opacity: 1;
  // transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.page-exit {
  opacity: 0;
}
.page-exit-active {
  opacity: 0;
  // transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-in-enter {
  opacity: 0;
}
.fade-in-exit {
  opacity: 1;
  display: none;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 0ms;
  display: none;
}

.form-slide-enter .left {
  transform: translateX(100%);
}

.form-slide-enter-active .left {
  transform: translateX(0%);
}

.form-slide-exit .left {
  transform: translateX(-100%);
  opacity: 1;
}

.form-slide-exit-active .left {
  transform: translateX(-100%);
  opacity: 1;
}

.form-slide-enter .right {
  opacity: 0;
  transform: translateX(-100%);
}

.form-slide-enter-active .right {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms;
}

.form-slide-exit .right {
  transform: translateX(10%);
}

.form-slide-exit-active .right {
  transform: translateX(10%);
}

.route__container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: 1s;
}

.left .route-enter {
  transform: translateX(100%);
}

.left .route-enter-active {
  transform: translateX(0%);
}

.left .route-exit {
  transform: translateX(-100%);
  opacity: 1;
}

.left .route-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 300ms;
}

.right .route-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.right .route-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms;
}

.right .route-exit {
  transform: translateX(10%);
}

.right .route-exit-active {
  transform: translateX(10%);
}
