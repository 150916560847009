aside {
  z-index: 100 !important;
}

.sidebar-wrapper {
  background-color: map-get($dark, '400');
  width: 100%;
  margin-left: 0rem;
  letter-spacing: 0.02rem;
  overflow: none;
  -ms-overflow-style: none; /* IE and Edge */

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: 8px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  @include transition(margin 0.25s ease-out);
  .nav-item {
    font-family: 'Open Sans';
    border: 0px !important;
    a {
      background-color: 'transparent';
      color: rgba(255, 255, 255, 0.7);
      font-weight: 600;
      letter-spacing: -0.02rem;
    }
    a {
      @include transition-all;
      .nav-link-icon {
        vertical-align: middle;
        letter-spacing: normal;
        &.icon-hover {
          span:first-child {
            display: inline;
          }
          span:nth-child(2) {
            display: none;
          }
        }
      }
      &.active,
      &:hover {
        color: $white;
        .nav-link-icon {
          &.icon-hover {
            span:first-child {
              display: none;
            }
            span:nth-child(2) {
              display: inline;
            }
          }
        }
      }
    }
  }
  .sidebar-section {
    background-color: map-get($dark, '400');
    overflow: overlay;
    & .clickable-nav-link {
      padding: 0.38rem 1rem !important;
    }
    & .particular-section {
      letter-spacing: 0.2rem;
    }
    .nav-item {
      font-size: 0.875rem;
    }
  }
}

.sidebar-wrapper .nav-item a.active .icon-journey,
.nav-item:hover .icon-journey {
  .icon-journey-circle {
    stroke: #ffffff;
  }
  .icon-journey-circle-core {
    fill: #ffffff;
  }
}

#content {
  margin-top: 3.6rem;
}

.custom-tabs {
  .nav-item {
    .nav-link {
      letter-spacing: 6px;
      color: $black;
      padding: 0.5rem 2rem;
      border: none !important;
      font-family: 'Montserrat';
      @include transition(color 0.25s ease-in-out);
      .nav-link-icon {
        @include transition-all;
        display: none;
      }
      &.active {
        color: #bf9000;
        border: none !important;
        font-weight: 500;
        .nav-link-icon {
          display: inline;
          vertical-align: middle;
          letter-spacing: normal;
        }
      }
      &:hover {
        color: #d5a000;
        border: none !important;
      }
    }
  }
}

.saffron-tabs {
  .nav-item {
    .nav-link {
      letter-spacing: 6px;
      color: $black;
      padding: 0.5rem 4rem;
      font-family: 'Montserrat';
      background: #f2f5f5;
      border: 1px solid #ffffff;
      border-radius: unset;
      .nav-link-icon {
        @include transition-all;
        display: none;
      }
      &.active,
      &:hover {
        color: $white;
        background: rgba(239, 192, 48, 0.8);
        font-weight: 500;
        .nav-link-icon {
          display: inline;
          vertical-align: middle;
          letter-spacing: normal;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #content {
    margin-top: 0rem;
    width: 100%;
  }
  .sidebar-wrapper {
    margin-left: 0rem;
    &.hide {
      margin-left: -13.75rem;
    }
  }
  .sidebar-wrapper-with-play-bar {
    padding-bottom: 6rem;
  }
  .custom-tabs {
    .nav-item {
      .nav-link {
        &.active {
          .nav-link-icon {
            .icon-help {
              display: inline;
            }
            .intentions-tooltip-icon {
              display: none;
            }
            &:hover {
              .icon-help {
                display: none;
              }
              .intentions-tooltip-icon {
                display: inline;
              }
            }
          }
        }
      }
      &:first-child {
        .nav-link {
          text-align: right;
        }
      }
      &:nth-child(2) {
        .nav-link {
          text-align: left;
        }
      }
      &:nth-child(n + 2) {
        border-left: 1px solid black;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .custom-tabs {
    .nav-item {
      &:nth-child(n + 2) {
        border-left: 1px solid black;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .custom-pr-sm-0 {
    padding-right: 0;
  }
  .custom-pl-sm-0 {
    padding-left: 0;
  }
  .pt-sm-sidebar {
    padding-top: 3.5rem !important;
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-content {
    z-index: 100;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .content-gutter {
    z-index: -1;
  }
}

@include media-breakpoint-down(sm) {
  .custom-tabs {
    .nav-item {
      position: relative;
      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          bottom: 1px;
          left: 30%;
          right: 0;
          width: 40%;
          border-top: 1px solid black;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #content {
    width: 100vw;
  }
  .sidebar-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    @include transition(left 0.25s ease-out);
    &.hide {
      left: -100vw;
    }
  }
  .sidebar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include transition(left 0.25s ease-out);
    &.hide {
      left: -100vw;
    }
  }
  .profile-options {
    bottom: 3.5rem !important;
  }
  .sidebar-wrapper-with-play-bar {
    padding-bottom: 4.25rem;
  }
  .custom-tabs {
    .nav-item {
      .nav-link {
        &.active {
          .nav-link-icon {
            .icon-help {
              display: none;
            }
            .intentions-tooltip-icon {
              display: inline;
            }
          }
        }
      }
    }
  }
}

.nav-header {
  z-index: 10;
}

.custom-pl-0-8 {
  padding-left: 0.8rem;
}
.custom-pl-0-3 {
  padding-left: 0.3rem;
}
.custom-mt-0-38 {
  margin-top: 0.38rem;
}
.custom-mb-1-6 {
  margin-bottom: 1.6rem;
}
